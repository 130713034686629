<template>
    <div id="footer-buttons" class="sticky-bottom bg-white fixed-to-bottom">
        <div v-if="$store.state.step === 1" class="row button-row">
            <div class="col button-col">
                <div class="button-wrapper">
                    <button v-if="!$store.state.show_cargo_for_transport" class="btn btn-primary" @click="goToList">{{ t('Overzicht') }}</button>
                    <button v-if="$store.state.show_cargo_for_transport" class="btn btn-secondary" @click="hideTransportCargo">{{ t('Terug') }}</button>
                </div>
            </div>
            <div class="col button-col text-center">
                <div class="button-wrapper">
                    <button v-if="!$store.state.show_cargo_for_transport" class="btn btn-secondary" @click="showTransportCargo">{{ t('Lading') }}</button>
                </div>
            </div>
            <div class="col button-col text-end">
                <div class="button-wrapper">
                    <button class="btn btn-success" @click="step2">{{ t('Start') }}</button>
                </div>
            </div>
        </div>
        <div v-if="$store.state.step === 2" class="row button-row">
            <div class="col button-col">
                <div class="button-wrapper">
                    <button class="btn btn-primary" @click="step1">{{ t('Terug') }}</button>
                </div>
            </div>
            <div class="col button-col text-center">

            </div>
            <div class="col button-col text-end">
                <div class="button-wrapper">
                    <button :disabled="!$store.state.step2valid" class="btn btn-success" @click="step3">{{ t('Verder') }}</button>
                </div>
            </div>
        </div>
        <div v-if="$store.state.step === 3" class="row button-row">
            <div class="col button-col">
                <div class="button-wrapper">
                    <button class="btn btn-primary" @click="step2">{{ t('Terug') }}</button>
                </div>
            </div>
            <div class="col button-col text-center">

            </div>
            <div class="col button-col text-end">
                <div class="button-wrapper">
                    <button :disabled="!$store.state.step3valid" class="btn btn-success" @click="step4">{{ t('Opslaan') }}</button>
                </div>
            </div>
        </div>
        <div v-if="$store.state.step === 4" class="row button-row">
            <div class="col button-col">
                <div class="button-wrapper">
                    <button class="btn btn-primary" @click="step3">{{ t('Terug') }}</button>
                </div>
            </div>
            <div class="col button-col text-center">

            </div>
            <div class="col button-col text-end">
                <div class="button-wrapper">
                    <button :disabled="!$store.state.step4valid" class="btn btn-success" @click="step5">{{ t('Opslaan') }}</button>
                </div>
            </div>
        </div>
        <div v-if="$store.state.step === 5" class="row button-row">
            <div class="col-4 button-col">
                <div class="button-wrapper">
                    <button class="btn btn-primary" @click="step4">{{ t('Terug') }}</button>
                </div>
            </div>
            <div class="col-3 button-col text-center">

            </div>
            <div class="col-5 button-col text-end">
                <div class="button-wrapper">
                    <button :disabled="!$store.state.step5valid" class="btn btn-success" @click="startTransport">{{ t('Start transport') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    name: 'FooterButtons',

    data: () => ({

    }),

    methods: {
        goToList() {
            this.$store.dispatch('setSelectedTransport', null);
            this.$store.dispatch('setStep', null);
            this.$store.dispatch('resetTransportData');
            this.$router.push({ name: 'transportlist' });
        },
        showTransportCargo() {
            this.$store.dispatch('setShowCargoForTransport', true);
        },
        hideTransportCargo() {
            this.$store.dispatch('setShowCargoForTransport', false);
        },
        step1() {
            this.$store.dispatch('setStep', 1);
            this.$store.dispatch('setPageHeader', this.t(''))
            this.$store.dispatch('setStep2Valid', false);
        },
        step2() {
            this.$store.dispatch('setStep', 2);
            this.$store.dispatch('setPageHeader', this.t('Stap 1/4 - Selecteer startlocatie'))
        },
        step3() {
            this.$store.dispatch('setStep', 3);
            this.$store.dispatch('setPageHeader', this.t('Stap 2/4 - Geef ingezamelde aantallen in'))
        },
        step4() {
            this.$store.dispatch('setStep', 4);
            this.$store.dispatch('setPageHeader', this.t('Stap 3/4 - Opmerking en ondertekenen'))
        },
        step5() {
            if(this.$store.state.save_transporter_data_for_next_transports){
                this.$store.dispatch('setSaveTransporterDataForNextTransports', {
                    first_name: this.$store.state.transport_fields_update_values.transporter_first_name,
                    last_name: this.$store.state.transport_fields_update_values.transporter_last_name,
                    insertion: this.$store.state.transport_fields_update_values.transporter_insertion,
                    signature: this.$store.state.transport_fields_update_values.transporter_signature,
                });
            }
            this.$store.dispatch('setStep', 5);
            this.$store.dispatch('setPageHeader', this.t('Stap 4/4 - Ondertekening producent'))
        },
        startTransport() {
            this.$store.dispatch('setDoStartTransport', true);
        },
    },

    watch: {
        '$store.state.transport_fields_update_values.start_gps': {
            handler(val) {
                if(val && Object.keys(val).length > 0){
                    this.$store.dispatch('setStep2Valid', true);
                }else{
                    this.$store.dispatch('setStep2Valid', false);
                }
            },
            deep: true
        },
    },

    computed: {

    },
}

</script>

<style scoped>
#footer-buttons{
    padding: 5px;
    background-color: #f8f9fa;
    border-top: 1px solid #dee2e6;
}
.button-row {
    display: flex;
    justify-content: center;
    align-items: center;
}
.button-col {
    display: flex;
    justify-content: center;
    align-items: center;
}
.button-wrapper {
    width: 100%;
}
#footer-buttons button {
    font-size: 13px;
    width: 100%;
}

.fixed-to-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999; /* optional, to ensure it appears above other elements */
}
</style>
