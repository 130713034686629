<template>
    <div class="card p-2 mb-2">
        <div class="form-group">
            <label for="transporter_name">{{ t('Handtekening verwerker') }}</label>
            <div class="signature-wrapper">
                <signature-pad ref="signaturePad"
                               @signature-updated-receiver="handleSignatureUpdate"
                               :signature="signature"
                               :role="'receiver'"></signature-pad>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12">
                <div class="form-group">
                    <select v-model="receiver_selected_contact" class="form-select form-select-sm">
                        <option selected value="-1">{{t('Kies een bestaande contactpersoon')}}</option>
                        <option v-for="contact in available_contacts" :key="contact.id" :value="contact.id">
                            {{ formatName(contact.firstname, contact.lastname, contact.lastname_prefix) }}
                        </option>
                    </select>
                </div>
                <div class="row mt-4">
                    <div class="col-4 pe-0">
                        <div class="form-group">
                            <input type="text" class="form-control form-control-sm"
                                   :disabled="contact_selected"
                                   :placeholder="t('Voornaam')" v-model="receiver_first_name">
                        </div>
                    </div>
                    <div class="col-3 pe-0">
                        <div class="form-group">
                            <input type="text" class="form-control form-control-sm"
                                   :disabled="contact_selected"
                                   :placeholder="t('Tussenvoegsel')" v-model="receiver_insertion">
                        </div>
                    </div>
                    <div class="col-5">
                        <div class="form-group">
                            <input type="text" class="form-control form-control-sm"
                                   :disabled="contact_selected"
                                   :placeholder="t('Achternaam')" v-model="receiver_last_name">
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-5 pe-0">
                        <div class="form-group">
                            <input type="text" class="form-control form-control-sm"
                                   :disabled="contact_selected"
                                   :placeholder="t('E-mailadres')" v-model="receiver_email_address">
                        </div>
                    </div>
                    <div class="col-4 pe-0">
                        <div class="form-group">
                            <input type="phone" class="form-control form-control-sm"
                                   :disabled="contact_selected"
                                   :placeholder="t('+315421548754')" v-model="receiver_phone_number">
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <select :disabled="contact_selected"
                                    class="form-select form-select-sm" v-model="receiver_language">
                                <option value="nl">NL</option>
                                <option value="en">EN</option>
                                <option value="fr">FR</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div v-if="!contact_selected" class="row mt-2">
                    <div class="col-6">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="receiver_save_contact" id="save-contact">
                            <label class="form-check-label col-form-label-sm" for="save-contact">
                                {{ t('Contactpersoon opslaan') }}
                            </label>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="receiver_not_available" id="not-available">
                            <label class="form-check-label col-form-label-sm" for="not-available">
                                {{ t('Niemand aanwezig') }} /
                                {{ t('Tekent later') }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SignaturePad from "@/components/SignaturePad.vue";
import Agent from "@/agent";

export default {
    name: 'ReceiverSignPage',
    components: {
        SignaturePad
    },
    data: () => ({
        receiver_selected_contact: -1,
        available_contacts: [],
        receiver_first_name: '',
        receiver_last_name: '',
        receiver_insertion: '',
        receiver_email_address: '',
        receiver_phone_number: '',
        receiver_language: '',
        receiver_save_contact: false,
        receiver_not_available: false,
        signature: null,

    }),
    methods: {
        handleSignatureUpdate(base_64) {
            this.$store.dispatch('setReceiverSignature', base_64);
        },
        loadContacts(callback) {
            Agent.Transport.getContacts(this.$store.state.selected_receiver.transports[0].id, this.$store.state.selected_receiver.id).then(response => {
                this.available_contacts = response.data;
                if(callback) callback();
            }).catch(error => {
                console.log(error);
            });
        },
    },
    watch: {
        'receiver_selected_contact': function (val) {
            val = parseInt(val);

            if (val !== -1) {
                this.$store.dispatch('setReceiverSelectedContact', val);
                const contact = this.available_contacts.find(contact => contact.id === val);
                this.receiver_first_name = contact.firstname;
                this.receiver_last_name = contact.lastname;
                this.receiver_insertion = contact.lastname_prefix;
                this.receiver_email_address = contact.email;
                this.receiver_phone_number = contact.phone_number;
                this.receiver_language = contact.communication_language;
            } else {
                this.$store.dispatch('setReceiverSelectedContact', null);
                this.receiver_first_name = '';
                this.receiver_last_name = '';
                this.receiver_insertion = '';
                this.receiver_email_address = '';
                this.receiver_phone_number = '';
                this.receiver_language = this.$store.state.language;
            }
        },
        'receiver_first_name': function (val) {
            this.$store.dispatch('setReceiverFirstName', val);
        },
        'receiver_last_name': function (val) {
            this.$store.dispatch('setReceiverLastName', val);
        },
        'receiver_insertion': function (val) {
            this.$store.dispatch('setReceiverInsertion', val);
        },
        'receiver_email_address': function (val) {
            this.$store.dispatch('setReceiverEmailAddress', val);
        },
        'receiver_phone_number': function (val) {
            this.$store.dispatch('setReceiverPhoneNumber', val);
        },
        'receiver_language': function (val) {
            this.$store.dispatch('setReceiverLanguage', val);
        },
        'receiver_save_contact': function (val) {
            this.$store.dispatch('setReceiverSaveContact', val);
        },
        'receiver_not_available': function (val) {
            this.$store.dispatch('setReceiverNotAvailable', val);
        },
        '$store.state.do_end_transport': {
            handler(val){
                if(val){
                    this.$swal({
                        title: this.t('Transporten beëindigen'),
                        html: this.t('Weet je zeker dat je deze transporten wilt beëindigen?') + '<br><br>' +
                            '<table class="form-table">' +
                            `<tr><th>${this.t('Formuliernummer')}</th></tr>` +
                            this.$store.state.transports_to_unload.map(transport => `<tr><td>${transport.form_number}</td></tr>`).join('') +
                            '</table>',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: this.t('Ja, beëindig transporten'),
                        cancelButtonText: this.t('Annuleren'),
                    }).then((result) => {
                        if (result.isConfirmed) {
                            let payload = {
                                receiver_data: {
                                    first_name: this.$store.state.transport_fields_update_values.receiver_first_name,
                                    last_name: this.$store.state.transport_fields_update_values.receiver_last_name,
                                    insertion: this.$store.state.transport_fields_update_values.receiver_insertion,
                                    email_address: this.$store.state.transport_fields_update_values.receiver_email_address,
                                    phone_number: this.$store.state.transport_fields_update_values.receiver_phone_number,
                                    language: this.$store.state.transport_fields_update_values.receiver_language,
                                    save_contact: this.$store.state.transport_fields_update_values.receiver_save_contact,
                                    not_available: this.$store.state.transport_fields_update_values.receiver_not_available,
                                    selected_contact: this.$store.state.transport_fields_update_values.receiver_selected_contact,
                                    signature: this.$store.state.transport_fields_update_values.receiver_signature,
                                    end_gps: this.$store.state.transport_fields_update_values.end_gps,
                                },
                                transports: this.$store.state.transport_fields_update_values.transport_lines
                            };
                            Agent.Transport.endTransports(payload).then((result) => {
                                let errors = [];
                                let conflicts = [];
                                let successes = [];
                                if(Array.isArray(result.data)){
                                    result.data.forEach((response) => {
                                        if(response.status === 'error'){
                                            errors.push({
                                                form_number: this.$store.state.transports_to_unload.find(transport => transport.id === response.id).form_number,
                                                message: response.message
                                            });
                                        }else if(response.status === 'conflict'){
                                            conflicts.push({
                                                form_number: this.$store.state.transports_to_unload.find(transport => transport.id === response.id).form_number,
                                                message: response.message
                                            });
                                        }else if(response.status === 'success'){
                                            successes.push({
                                                form_number: this.$store.state.transports_to_unload.find(transport => transport.id === response.id).form_number,
                                                message: response.message
                                            });
                                        }
                                    });
                                }
                                let swal_html = '';

                                if(successes.length > 0){
                                    swal_html += `<h4>${this.t('Successen')}</h4><table class="form-table">`;
                                    successes.forEach((success) => {
                                        swal_html += `<tr><th>${success.form_number}</th></tr><tr><td>${success.message}</td></tr>`;
                                    });
                                    swal_html += '</table>';
                                }
                                if(conflicts.length > 0){
                                    swal_html += `<h4>${this.t('Conflicten')}</h4><table class="form-table">`;
                                    conflicts.forEach((conflict) => {
                                        swal_html += `<tr><th>${conflict.form_number}</th></tr><tr><td>${conflict.message}</td></tr>`;
                                    });
                                    swal_html += '</table>';
                                }
                                if(errors.length > 0){
                                    swal_html += `<h4>${this.t('Fouten')}</h4><table class="form-table">`;
                                    errors.forEach((error) => {
                                        swal_html += `<tr><th>${error.form_number}</th></tr><tr><td>${error.message}</td></tr>`;
                                    });
                                    swal_html += '</table>';
                                }

                                let swal_title = this.t('Transporten beëindigd');
                                let swal_type = 'success';
                                if (successes.length > 0 && (errors.length > 0 || conflicts.length > 0)) {
                                    swal_title = this.t('Transporten beëindigd met fouten');
                                    swal_type = 'warning';
                                }else if(successes.length === 0 && (errors.length > 0 || conflicts.length > 0)){
                                    swal_title = this.t('Transporten niet beëindigd');
                                    swal_type = 'error';
                                }else if(successes.length === 0 && errors.length === 0 && conflicts.length === 0){
                                    swal_title = this.t('Transporten niet beëindigd. Er is iets mis gegaan...');
                                    swal_type = 'error';
                                }
                                this.$swal({
                                    title: swal_title,
                                    icon: swal_type,
                                    html: swal_html,
                                    confirmButtonText: this.t('Ok'),
                                }).then(() => {
                                    if(successes.length > 0){
                                        this.$store.dispatch('setShowUnloadPage', false);
                                        this.$store.dispatch('setShowReceiverSignPage', false);
                                        this.$store.dispatch('setTransportsToUnload', []);
                                        this.$store.dispatch('setUnloadStep', null);
                                        this.$store.dispatch('setSelectedTransports', []);
                                        this.$store.dispatch('setSelectedReceiver', null);
                                        this.$store.dispatch('setReceiverSignature', null);
                                        this.$store.dispatch('setReceiverSelectedContact', null);
                                        this.$store.dispatch('setReceiverFirstName', '');
                                        this.$store.dispatch('setReceiverLastName', '');
                                        this.$store.dispatch('setReceiverInsertion', '');
                                        this.$store.dispatch('setReceiverEmailAddress', '');
                                        this.$store.dispatch('setReceiverPhoneNumber', '');
                                        this.$store.dispatch('setReceiverLanguage', this.$store.state.language);
                                        this.$store.dispatch('setReceiverSaveContact', false);
                                        this.$store.dispatch('setReceiverNotAvailable', false);
                                        this.$store.dispatch('setReceiverSignature', null);
                                        this.$store.dispatch('resetTransportData');
                                        this.updateAllTransportLists();
                                    }
                                    this.$store.dispatch('setDoEndTransport', false);
                                })
                            }).catch((error) => {
                                this.$swal({
                                    title: this.t('Er is iets misgegaan, probeer opnieuw.'),
                                    icon: 'error',
                                    text: error.response.data.message,
                                    confirmButtonText: this.t('Ok'),
                                }).then(() => {
                                    this.$store.dispatch('setDoEndTransport', false);
                                })
                            });

                        }
                        this.$store.dispatch('setDoEndTransport', false);
                    });
                }
            },
            deep: true
        }
    },

    mounted() {
        this.receiver_language = this.$store.state.language;
        if(this.$store.state.transport_fields_update_values && this.$store.state.transport_fields_update_values.receiver_signature) {
            this.signature = this.$store.state.transport_fields_update_values.receiver_signature;
        }
        if(this.$store.state.transport_fields_update_values && this.$store.state.transport_fields_update_values.receiver_save_contact_details) {
            this.receiver_save_contact = this.$store.state.transport_fields_update_values.receiver_save_contact_details;
        }
        if(this.$store.state.transport_fields_update_values && this.$store.state.transport_fields_update_values.receiver_not_available) {
            this.receiver_not_available = this.$store.state.transport_fields_update_values.receiver_not_available;
        }
        if (this.$store.state.transport_fields_update_values && this.$store.state.transport_fields_update_values.receiver_first_name) {
            this.receiver_first_name = this.$store.state.transport_fields_update_values.receiver_first_name;
        }
        if (this.$store.state.transport_fields_update_values && this.$store.state.transport_fields_update_values.receiver_last_name) {
            this.receiver_last_name = this.$store.state.transport_fields_update_values.receiver_last_name;
        }
        if (this.$store.state.transport_fields_update_values && this.$store.state.transport_fields_update_values.receiver_insertion) {
            this.receiver_insertion = this.$store.state.transport_fields_update_values.receiver_insertion;
        }
        if (this.$store.state.transport_fields_update_values && this.$store.state.transport_fields_update_values.receiver_email_address) {
            this.receiver_email_address = this.$store.state.transport_fields_update_values.receiver_email_address;
        }
        if (this.$store.state.transport_fields_update_values && this.$store.state.transport_fields_update_values.receiver_phone_number) {
            this.receiver_phone_number = this.$store.state.transport_fields_update_values.receiver_phone_number;
        }
        if (this.$store.state.transport_fields_update_values && this.$store.state.transport_fields_update_values.receiver_language) {
            this.receiver_language = this.$store.state.transport_fields_update_values.receiver_language;
        }
        this.loadContacts(() => {
            if (this.$store.state.transport_fields_update_values && this.$store.state.transport_fields_update_values.receiver_selected_contact) {
                this.receiver_selected_contact = this.$store.state.transport_fields_update_values.receiver_selected_contact;
            }
        })
    },
    computed: {
        contact_selected() {
            return parseInt(this.receiver_selected_contact) !== -1;
        },
    }
}

</script>

<style scoped>

</style>