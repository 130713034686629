<template>
    <div v-for="(transport, index) in transports_to_unload" :key="index">
        <div v-if="manager[index]" class="row transport-item">
            <div class="col-12 card p-2 mb-2">
                <div>{{transport.form_number}}</div>
            </div>
            <ul id="update-cargo-list">
                <li class="card mb-2 p-2" :key="index" v-for="(item, index) in cloned_lines.find((tp) => tp.transport_id === transport.id).lines">
                    <div class="row mb-3" >
                        <div class="col-9">
                            <textarea class="form-control form-control-sm" rows="2" v-model="item.waste_description"></textarea>
                        </div>
                        <div class="col-3 ps-0">
                            <div class="input-group input-group-sm">
                                <input type="number" class="form-control form-control-sm" v-model="item.actual_weight">
                                <span class="input-group-text">{{t('kg')}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-9">
                            <input type="text" :placeholder="t('verpakkingstype')" class="form-control form-control-sm" v-model="item.packaging_type">
                        </div>
                        <div class="col-3 ps-0">
                            <div class="input-group input-group-sm">
                                <input type="number" class="form-control form-control-sm" v-model="item.amount_of_packages">
                                <span class="input-group-text">{{t('st')}}</span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

export default {
    name: 'UnloadCargoPage',
    data: () => ({
        transports_to_unload: [],
        cloned_lines: [],
        manager: {}
    }),
    computed: {

    },
    methods: {

    },
    watch: {
        '$store.state.transports_to_unload': {
            handler() {
                this.transports_to_unload = this.$store.state.transports_to_unload;
            },
            deep: true
        },
        'transports_to_unload': {
            handler() {
                this.$store.dispatch('setTransportsToUnload', this.transports_to_unload);
                this.transports_to_unload.forEach((transport, index) => {
                    this.manager[index] = index === 0;
                });
                this.$store.dispatch('setUnloadingTransportsManager', this.manager);
                this.cloned_lines = JSON.parse(JSON.stringify(this.transports_to_unload.map((transport) => {
                    return {
                        transport_id: transport.id,
                        transport_number: transport.form_number,
                        lines: transport.lines.map((line) => {
                            return {
                                ...line,
                                actual_weight: line.received_weight,
                            }
                        })
                    }
                })));
                this.$store.dispatch('setTransportLines', this.cloned_lines);
            },
            deep: true
        },
        'cloned_lines': {
            handler() {
                this.$store.dispatch('setTransportLines', this.cloned_lines);
            },
            deep: true
        }
    },
    mounted() {
        if(this.$store.state.transports_to_unload){
            this.transports_to_unload = this.$store.state.transports_to_unload;
        }

        if(this.$store.state.transport_fields_update_values.transport_lines && this.$store.state.transport_fields_update_values.transport_lines.length > 0) {
            this.cloned_lines = this.$store.state.transport_fields_update_values.transport_lines;
        }else {
            this.cloned_lines = JSON.parse(JSON.stringify(this.transports_to_unload.map((transport) => {
                return {
                    transport_id: transport.id,
                    transport_number: transport.form_number,
                    lines: transport.lines.map((line) => {
                        return {
                            ...line,
                            actual_weight: line.received_weight,
                        }
                    })
                }
            })));
            this.$store.dispatch('setTransportLines', this.cloned_lines);
        }
        this.$swal({
            title: this.t('Overhandig dit apparaat aan de verwerker'),
            html: "" +
                "<p>" + this.t('De verwerker dient de ontvangen lading te bevestigen en te ondertekenen.') + "</p>",
            icon: 'info',
            confirmButtonText: 'OK'
        })
    },
}

</script>

<style>



</style>