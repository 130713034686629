<template>
    <teleport to="body">
        <div v-show="loading" class="global-loader">
            <div class="loader">
                <img src="../assets/loading.svg" alt="Loading...">
            </div>
        </div>
    </teleport>
</template>

<script>

export default {
    name: 'GlobalLoader',
    data: () => ({
        loading: false,
    }),
    watch: {
        '$store.state.is_loading': {
            handler: function (val, oldVal) {
                this.loading = val;
            },
            deep: true,
        },
    },
}

</script>

<style scoped>
.global-loader {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>