<template>
    <div v-if="transport" id="single-transport-view">
        <div v-if="$store.state.step === 1">
            <transport-view :transport="$store.state.selected_transport"/>
        </div>
        <div v-if="$store.state.step === 2">
            <map-page/>
        </div>
        <div v-if="$store.state.step === 3">
            <cargo-page/>
        </div>
        <div v-if="$store.state.step === 4">
            <transporter-sign-page/>
        </div>
        <div v-if="$store.state.step === 5">
            <producer-sign-page/>
        </div>
    </div>

    <modal-component/>

</template>

<script>

import ModalComponent from "@/components/Modal.vue";
import MapPage from "@/components/MapPage.vue";
import CargoPage from "@/components/CargoPage.vue";
import TransportView from "@/components/TransportView.vue";
import TransporterSignPage from "@/components/TransporterSignPage.vue";
import ProducerSignPage from "@/components/ProducerSignPage.vue";
import Agent from "@/agent";


export default {
    name: 'SingleTransport',
    components: {
        TransporterSignPage,
        MapPage,
        ModalComponent,
        CargoPage,
        TransportView,
        ProducerSignPage
    },

    data: () => ({}),

    computed: {
        transport() {
            return this.$store.state.selected_transport ? this.$store.state.selected_transport : null;
        },
    },

    methods:{
        startTransport(){
            const payload = {
                transport_id: this.transport.id,
                transporter_signature: this.$store.state.transport_fields_update_values.transporter_signature,
                transporter_first_name: this.$store.state.transport_fields_update_values.transporter_first_name,
                transporter_last_name: this.$store.state.transport_fields_update_values.transporter_last_name,
                transporter_insertion: this.$store.state.transport_fields_update_values.transporter_insertion,
                transport_remark: this.$store.state.transport_fields_update_values.transport_remark,
                producer_signature: this.$store.state.transport_fields_update_values.producer_signature,
                producer_first_name: this.$store.state.transport_fields_update_values.producer_first_name,
                producer_last_name: this.$store.state.transport_fields_update_values.producer_last_name,
                producer_insertion: this.$store.state.transport_fields_update_values.producer_insertion,
                producer_phone_number: this.$store.state.transport_fields_update_values.producer_phone_number,
                producer_email_address: this.$store.state.transport_fields_update_values.producer_email_address,
                producer_language: this.$store.state.transport_fields_update_values.producer_language,
                producer_not_available: this.$store.state.transport_fields_update_values.producer_not_available,
                producer_selected_contact: this.$store.state.transport_fields_update_values.producer_selected_contact,
                producer_save_contact: this.$store.state.transport_fields_update_values.producer_save_contact,
                start_gps: this.$store.state.transport_fields_update_values.start_gps,
                transport_lines: this.$store.state.transport_fields_update_values.transport_lines,
            };

            if(payload.producer_selected_contact){
                payload.producer_first_name = null;
                payload.producer_last_name = null;
                payload.producer_insertion = null;
                payload.producer_save_contact = null;
                payload.producer_not_available = null;
                payload.producer_phone_number = null;
                payload.producer_email_address = null;
                payload.producer_language = null;
            }

            Agent.Transport.startTransport(payload).then(
                (response) => {
                    this.$swal({
                        title: this.t('Transport gestart'),
                        text: this.transport.form_number,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then(
                        () => {
                            this.$store.dispatch('setDoStartTransport', false);
                            this.$store.dispatch('resetTransportData');
                            this.updateAllTransportLists();
                        }
                    );
                }
            ).catch((error) => {
                this.$swal({
                    title: this.t('Transport') + ' ' + this.transport.form_number + ' ' + this.t('is NIET gestart'),
                    html: "" +
                        "<p>" + this.t('Er is iets misgegaan bij het starten van het transport') + "</p>" +
                        "<p>" + this.t('Foutmelding') + ": " + this.t(error.response.data.message) + "</p>",
                    icon: 'error',
                    confirmButtonText: 'OK'
                }).then(
                    () => {
                        this.$store.dispatch('setDoStartTransport', false);
                    }
                )
            });
        }
    },

    watch: {
        '$store.state.do_start_transport': function(newValue) {
            if(newValue) {
                this.startTransport();
            }
        },
    },

    mounted() {
        this.$store.dispatch('setStep2Valid', false);
    }
}
</script>
<style>
.send-email {
    color: #000;
}

.send-email:hover {
    color: #000;
}

#single-transport-view {
    line-height: 1.2;
    padding-top: 10px;
    padding-bottom: 10px;
}

.navigate {
    font-size: 1.2rem;
    padding: 5px;
    border: 1px solid #ccc;
    width: 30px;
    height: 30px;
    display: block;
    text-decoration: none;
    text-align: center;
    border-radius: 50%;
}

.navigate i {
    display: block;
}

</style>s