const translations = {
    "Plaats": {
        "en": "Place",
        "fr": "Place"
    },
    "Datum": {
        "en": "Date",
        "fr": "Date"
    },
    "Relatie": {
        "en": "Relation",
        "fr": "Relation"
    },
    "Sorteer op": {
        "en": "Sort by",
        "fr": "Trier par"
    },
    "Vul uw e-mailadres in": {
        "en": "Enter your email address",
        "fr": "Entrez votre adresse e-mail"
    },
    "Code opvragen": {
        "en": "Request code",
        "fr": "Demander un code"
    },
    "Terug": {
        "en": "Back",
        "fr": "Retour"
    },
    "Inloggen": {
        "en": "Login",
        "fr": "Connexion"
    },
    "Vul hier de code uit uw mailbox in": {
        "en": "Enter the code from your mailbox here",
        "fr": "Entrez le code de votre boîte aux lettres ici"
    },
    "in": {
        "en": "in",
        "fr": "dans"
    },
    'Bekijk': {
        "en": "View",
        "fr": "Voir"
    },
    'Afvalstoffenproducent': {
        "en": "Waste producer",
        "fr": "Producteur de déchets"
    },
    'Adres van verzending': {
        "en": "Address of shipment",
        "fr": "Adresse d'expédition"
    },
    'Vervoerder': {
        "en": "Carrier",
        "fr": "Transporteur"
    },
    'Verwerker': {
        "en": "Processor",
        "fr": "Processeur"
    },
    'Opmerkingen aan chauffeur': {
        "en": "Note to driver",
        "fr": "Note au conducteur"
    },
    'Overzicht': {
        "en": "Overview",
        "fr": "Aperçu"
    },
    'Lading': {
        "en": "Load",
        "fr": "Charge"
    },
    'Start': {
        "en": "Start",
        "fr": "Début"
    },
    'Overhandig dit apparaat aan de verwerker': {
        "en": "Hand this device to the processor",
        "fr": "Remettez cet appareil au processeur"
    },
    'De verwerker dient de ontvangen lading te bevestigen en te ondertekenen.': {
        "en": "The processor must confirm and sign the received load.",
        "fr": "Le processeur doit confirmer et signer la charge reçue."
    },
    'Stap:': {
        "en": "Step:",
        "fr": "Étape:"
    },
    'Selecteer loslocatie': {
        "en": "Select unloading location",
        "fr": "Sélectionnez l'emplacement de déchargement"
    },
    'Ontvangen gewichten en aantallen': {
        "en": "Received weights and quantities",
        "fr": "Poids et quantités reçus"
    },
    'Handtekening verwerker': {
        "en": "Processor signature",
        "fr": "Signature du processeur"
    },
    'Verversen': {
        "en": "Refresh",
        "fr": "Rafraîchir"
    },
    'Lading tonen': {
        "en": "Show load",
        "fr": "Montrer la charge"
    },
    'Uitloggen': {
        "en": "Logout",
        "fr": "Déconnexion"
    },
    'Open': {
        "en": "Open",
        "fr": "Ouvrir"
    },
    'Transporten': {
        "en": "Transports",
        "fr": "Transports"
    },
    'Geladen voor': {
        "en": "Loaded for",
        "fr": "Chargé pour"
    },
    'Verstuur': {
        "en": "Send",
        "fr": "Envoyer"
    },
    'Verder': {
        "en": "Next",
        "fr": "Suivant"
    },
    'Stap 1/4 - Selecteer startlocatie': {
        "en": "Step 1/4 - Select start location",
        "fr": "Étape 1/4 - Sélectionnez l'emplacement de départ"
    },
    'Stap 2/4 - Geef ingezamelde aantallen in': {
        "en": "Step 2/4 - Enter collected quantities",
        "fr": "Étape 2/4 - Entrez les quantités collectées"
    },
    'Stap 3/4 - Opmerking en ondertekenen': {
        "en": "Step 3/4 - Note and sign",
        "fr": "Étape 3/4 - Note et signature"
    },
    'Stap 4/4 - Ondertekening producent': {
        "en": "Step 4/4 - Signing producer",
        "fr": "Étape 4/4 - Signature du producteur"
    },
    'Opslaan': {
        "en": "Save",
        "fr": "Enregistrer"
    },
    'Verpakkingstype': {
        "en": "Packaging type",
        "fr": "Type d'emballage"
    },
    'Chem. samenstelling': {
        "en": "Chem. composition",
        "fr": "Composition chimique"
    },
    'Fys. eigenschappen': {
        "en": "Phys. properties",
        "fr": "Propriétés physiques"
    },
    'Verwerking': {
        "en": "Processing",
        "fr": "Traitement"
    },
    'Gewicht': {
        "en": "Weight",
        "fr": "Poids"
    },
    'Omschrijving': {
        "en": "Description",
        "fr": "Description"
    },
    'Handtekening vervoerder': {
        "en": "Driver signature",
        "fr": "Signature du conducteur"
    },
    'Opmerking behorend bij transport': {
        "en": "Note belonging to transport",
        "fr": "Note appartenant au transport"
    },
    'Voornaam': {
        "en": "First name",
        "fr": "Prénom"
    },
    'Tussenvoegsel': {
        "en": "Insertion",
        "fr": "Insertion"
    },
    'Achternaam': {
        "en": "Last name",
        "fr": "Nom de famille"
    },
    'Naam en handtekening onthouden voor volgende transporten': {
        "en": "Remember name and signature for next transports",
        "fr": "Mémoriser le nom et la signature pour les prochains transports"
    },
    'Handtekening producent': {
        "en": "Producer signature",
        "fr": "Signature du producteur"
    },
    'Kies een bestaande contactpersoon': {
        "en": "Choose an existing contact person",
        "fr": "Choisissez une personne de contact existante"
    },
    'Contactpersoon opslaan': {
        "en": "Save contact person",
        "fr": "Enregistrer la personne de contact"
    },
    'Niemand aanwezig': {
        "en": "No one present",
        "fr": "Personne présente"
    },
    'Start transport': {
        "en": "Start transport",
        "fr": "Début du transport"
    },
    'E-mailadres': {
        "en": "Email address",
        "fr": "Adresse e-mail"
    },
    'verpakkingstype': {
        "en": "packaging type",
        "fr": "type d'emballage"
    },
    'Retour:': {
        "en": "Return:",
        "fr": "Retour:"
    },
    'Geweigerd': {
        "en": "Refused",
        "fr": "Refusé"
    },
    'Lossen': {
        "en": "Unloading",
        "fr": "Déchargement"
    },
    'Geen open transporten gevonden': {
        "en": "No open transports found",
        "fr": "Aucun transport ouvert trouvé"
    },
    'Transporten beëindigen': {
        "en": "End transports",
        "fr": "Fin des transports"
    },
    'Weet je zeker dat je deze transporten wilt weigeren?': {
        "en": "Are you sure you want to refuse these transports?",
        "fr": "Êtes-vous sûr de vouloir refuser ces transports?"
    },
    'Je kunt deze actie niet ongedaan maken.': {
        "en": "You cannot undo this action.",
        "fr": "Vous ne pouvez pas annuler cette action."
    },
    'Formuliernummer': {
        "en": "Form number",
        "fr": "Numéro de formulaire"
    },
    'Ja, weigeren': {
        "en": "Yes, refuse",
        "fr": "Oui, refuser"
    },
    'Annuleren': {
        "en": "Cancel",
        "fr": "Annuler"
    },
    'Reden voor weigering transporten': {
        "en": "Reason for refusal of transports",
        "fr": "Motif de refus des transports"
    },
    'Vervolgactie': {
        "en": "Follow-up action",
        "fr": "Action de suivi"
    },
    'Terug naar producent': {
        "en": "Back to producer",
        "fr": "Retour au producteur"
    },
    'Naar andere verwerker': {
        "en": "To another processor",
        "fr": "À un autre processeur"
    },
    'Weigeren': {
        "en": "Refuse",
        "fr": "Refuser"
    },
    'Ja, beëindig transporten': {
        "en": "Yes, end transports",
        "fr": "Oui, fin des transports"
    },
    'Weet je zeker dat je deze transporten wilt beëindigen?': {
        "en": "Are you sure you want to end these transports?",
        "fr": "Êtes-vous sûr de vouloir mettre fin à ces transports?"
    },
    'Beëindigen': {
        "en": "End",
        "fr": "Fin"
    },
    'Transport gestart': {
        "en": "Transport started",
        "fr": "Transport commencé"
    },
    'Stap 1/3 - Selecteer loslocatie': {
        "en": "Step 1/3 - Select unloading location",
        "fr": "Étape 1/3 - Sélectionnez l'emplacement de déchargement"
    },
}

const t = (key, store) => {
    const language = store.state.language;
    if (translations[key] && translations[key][language]) {
        return translations[key][language];
    }
    return key;
}

export default t;