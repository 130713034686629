<template>
    <div>
        <div class="row">
            <div class="col-8 mb-2">
               <div class="search-bar">
                   <input type="text" class="form-control" placeholder="Search" v-model="search_term" aria-label="Search" aria-describedby="basic-addon1">
               </div>
            </div>
<!--            <div class="col-4 text-end action-buttons">-->
<!--                <div class="btn-group" role="group">-->
<!--                    <a class="btn"><i class="fa fa-map-marker" @click="goToMap"></i> </a>-->
<!--                    <a class="btn"><i class="fa fa-calendar" @click="goToCalendar"></i> </a>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
    export default {
        name: 'UtilitiesBar',

        data: () => ({
            search_term: '',
        }),

        methods: {
            goToMap() {
                this.$router.push({ name: 'map' });
            },
            goToCalendar() {
                this.$router.push({ name: 'calendar' });
            },
        },

        watch: {
            search_term: function(newValue) {
                this.$store.dispatch('setSearchTerm', newValue);
            }
        }

    }
</script>

<style scoped>
    .action-buttons a.btn i{
        font-size: 1.7rem;
    }

</style>