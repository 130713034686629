<template>
    <div v-if="!$store.state.selected_loaded_transport">
        <div v-if="!selected_receiver">
            <div v-for="(receiver, index) in receivers" :key="index" class="row transport-item">
                <div class="col-12 card p-2">
                    <div class="row">
                        <div class="col-10">
                            <strong class="receiver-name text-success">{{ translate(receiver.name) }}</strong>
                            <div>
                                {{ translate(receiver.address.street) }}
                                {{ receiver.address.house_number }}{{ this.stringIfNull(receiver.address.house_number_addition) }}
                                <strong>{{ t('in') }}</strong> {{ translate(receiver.address.city) }}
                            </div>
                        </div>
                        <div class="col-2">
                            <a class="navigate" @click="openMap(receiver.address)" href="javascript:void(0)">
                                <i class="fa fa-location-arrow"></i>
                            </a>
                        </div>
                    </div>
                    <div v-for="(transport, i) in loadedTransportsForReceiver(receiver.address.id)" :key="i" class="row cargo-item">
                        <div class="col-10">
                            {{ transport.form_number }}
                        </div>
                        <div class="col-2">
                            <span class="no-line-break">{{ formatWeight(totalWeightOfTransport(transport)) }}</span>
                        </div>
                    </div>
                    <div class="cargo-item-footer">
                        <div class="row">
                            <div class="col-4">

                            </div>
                            <div class="col-5">
                            </div>
                            <div class="col-3">
                                <button class="btn btn-primary btn-xs" @click="openReceiver(receiver)">{{ t('Bekijk') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!$store.state.loaded_transports.length">
                <div class="row bg-white pt-3">
                    <div class="col-12">
                        <div class="text-center">
                            <p>{{t('Geen geladen transporten gevonden')}}</p>
                            <button class="btn btn-primary" @click="this.updateAllTransportLists(['loaded']);">{{ t('Verversen') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="selected_receiver">
            <div class="row transport-item">
                <div class="col-12 card p-2">
                    <div class="row">
                        <div class="col-9">
                            {{ t('Geladen voor') }} {{ selected_receiver.name }}
                        </div>
                        <div class="col-3 align-self-center text-end">
                            <a class="send-email" href="javascript:void(0)" @click="show_mail_dialog = !show_mail_dialog">
                                <i class="fa fa-envelope"></i>
                            </a>
                        </div>
                        <div v-if="show_mail_dialog" class="col-12">
                            <div class="form-inline">
                                <div class="form-group d-inline-block mr-2">
                                    <input v-model="email_address" class="form-control form-control-sm" placeholder="Email"
                                           type="email">
                                </div>
                                <div class="form-group d-inline-block ms-1">
                                    <button :disabled="!emailIsValid"
                                            class="btn btn-primary btn-sm"
                                            type="button"
                                            @click="sendReceiverEmail(email_address)">
                                        {{ t('Verstuur') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-for="(transport, index) in selected_receiver.transports" :key="index" class="col-12 card p-2 mt-2">
                    <div class="row">
                        <div class="col-10">
                            <strong class="transport-disposer-name">{{ translate(transport.disposer.name) }}</strong>
                            <div>
                                {{ translate(transport.origin.street) }}
                                {{ transport.origin.house_number }}{{ this.stringIfNull(transport.origin.house_number_addition) }}
                                <strong>{{ t('in') }}</strong> {{ translate(transport.origin.city) }}
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="custom-control custom-checkbox checkbox-xl">
                                <input :checked="$store.state.selected_transports.indexOf(transport.id) !== -1" type="checkbox" class="custom-control-input big-checkbox check" @click="toggleSelectedTransport(transport.id)">
                            </div>
                        </div>
                    </div>
                    <div v-for="(line, i) in transport.lines" :key="i" class="row cargo-item">
                        <div class="col-10">
                            {{ translate(line.waste_description) }}
                        </div>
                        <div class="col-2">
                            <span class="no-line-break">{{ formatWeight(line.received_weight) }}</span>
                        </div>
                    </div>
                    <div class="cargo-item-footer">
                        <div class="row">
                            <div class="col-4">
                                <span class="form-date-time">{{parseDateAndFormat(transport.start_transport_at)}}</span>
                            </div>
                            <div class="col-5">
                                <span class="form-number">{{transport.form_number}}</span>
                            </div>
                            <div class="col-3">
                                <button class="btn btn-warning btn-xs" @click="openLoadedTransport(transport.id)">{{ t('Bekijk') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="$store.state.selected_loaded_transport" class="pt-2" style="padding-bottom:70px;">
        <transport-view :transport="$store.state.selected_loaded_transport"/>
    </div>
</template>

<script>
import Agent from "@/agent";
import TransportView from "@/components/TransportView.vue";


export default {
    name: 'CurrentLoad',

    components: {
        TransportView,
    },

    data: () => ({
        fake_receiver_address: {
            street: 'Kerkstraat',
            house_number: '1',
            house_number_addition: 'a',
            city: 'Amsterdam',
            postal_code: '1234AB',
        },
        show_mail_dialog: false,
        email_address: '',
        selected_transports: [],
    }),

    computed: {
        receivers() {
            try{
                let receivers = {};
                this.$store.state.loaded_transports.forEach(transport => {
                    transport.lines.forEach(line => {
                        if (receivers[transport.receiver.address.id] === undefined) {
                            receivers[transport.receiver.address.id] = {
                                name: transport.receiver.name,
                                address: transport.receiver.address ? transport.receiver.address : this.fake_receiver_address,
                                transports: [],
                                cargos: [],
                                id: transport.receiver.id,
                            };
                        }
                        // each cargo has a waste_description and a weight. the descriptions must be unique and weights must be summed
                        let cargo = receivers[transport.receiver.address.id].cargos.find(cargo => cargo.waste_description === line.waste_description);
                        if (cargo === undefined) {
                            receivers[transport.receiver.address.id].cargos.push({
                                waste_description: line.waste_description,
                                received_weight: line.received_weight,
                            });
                        } else {
                            cargo.received_weight += line.received_weight;
                        }
                    });
                    receivers[transport.receiver.address.id].transports.push(transport);
                });
                return receivers;
            } catch (e) {
                console.log(e);
                return [];
            }
        },
        selected_receiver() {
            return this.$store.state.selected_receiver;
        },
        emailIsValid() {
            return this.validateEmail(this.email_address);
        },
    },

    methods: {
        openReceiver(receiver) {
            this.$store.dispatch('setSelectedReceiver', receiver);
            this.$store.dispatch('setSelectedTransports', []);
        },
        openLoadedTransport(transport_id) {
            Agent.Transport.getTransport(transport_id).then(response => {
                this.$store.dispatch('setSelectedLoadedTransport', response.data);
            }).catch(error => {
                console.log(error);
                this.$swal({
                    title: 'Error',
                    text: error.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            });
        },
        loadedTransportsForReceiver(receiver_address_id) {
            return this.$store.state.loaded_transports.filter(transport => transport.receiver.address.id === receiver_address_id);
        },
        sendReceiverEmail(email_address) {
            Agent.Transport.sendEmail(email_address, this.selected_receiver.transports.map((x) => x.id), this.$store.state.language).then(response => {
                this.$swal({
                    title: this.t('Email verzonden'),
                    text: this.t('De email is verzonden naar ') + email_address,
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
            }).catch(error => {
                console.log(error);
                this.$swal({
                    title: 'Error',
                    text: error.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            });
            this.show_mail_dialog = false
            this.email_address = ''
        },
        toggleSelectedTransport(transport_id){
            if(this.selected_transports.indexOf(transport_id) !== -1){
                this.selected_transports.splice(this.selected_transports.indexOf(transport_id), 1);
            }else{
                this.selected_transports.push(transport_id)
            }
            this.$store.dispatch('setSelectedTransports', this.selected_transports);
        }
    },

    watch: {
        '$store.state.selected_receiver': {
            handler(val) {
                if (val) {
                    this.$store.dispatch('setSelectedTransports', []);
                    if(val.transports && val.transports.length === 1){
                        this.$store.dispatch('setSelectedTransports', [val.transports[0].id]);
                    }
                }
            },
            deep: true

        },
        '$store.state.loaded_transports': {
            handler(val){
                if (val) {
                    this.$store.dispatch('setSelectedTransports', []);
                }
            },
            deep:true

        },
        '$store.state.selected_transports': {
            handler(){
                this.selected_transports = this.$store.state.selected_transports;
            },
            deep:true
        }
    },

    mounted() {
        if(this.$store.state.selected_transports.length) {
            this.selected_transports = this.$store.state.selected_transports;
        }else{
            this.selected_transports = [];
        }
    },
}


</script>

<style scoped>
.big-checkbox {width: 30px; height: 30px;}
</style>