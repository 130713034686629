<template>
    <div>
        <div v-if="qrCodeData">
            <img :src="qrCodeData" alt="QR Code" />
        </div>
    </div>
</template>

<script>
import QRCode from 'qrcode';

export default {
    name: 'QRCodeGenerator',
    props: {
        inputUrl: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            qrCodeData: null,
        };
    },
    watch: {
        inputUrl: {
            immediate: true,
            handler(newValue) {
                this.generateQRCode(newValue);
            }
        }
    },
    methods: {
        async generateQRCode(url) {
            try {
                this.qrCodeData = await QRCode.toDataURL(url);
            } catch (error) {
                console.error('Error generating QR Code:', error);
            }
        }
    }
};
</script>