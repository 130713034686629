<template>
    <div class="container-fluid bg-color">
        <div>
            <div class="sticky-top bg-white row">
                <main-nav/>
                <div v-if="$store.state.page_header" class="page-header">{{ $store.state.page_header }}</div>
                <utilities-bar v-if="!selected_transport && !selected_receiver && status === 'open'"/>
                <sorting-bar v-if="!selected_transport && !selected_receiver && status === 'open'"/>

            </div>
            <single-transport v-if="selected_transport"/>
            <div v-if="!selected_transport && status === 'open'">
                <div v-for="(transport, index) in transports" :key="index" class="row transport-item">
                    <div class="col-12 card p-2">
                        <div class="row">
                            <div class="col-10">
                                <strong class="transport-disposer-name">{{ translate(transport.disposer.name) }}</strong>
                                <div>
                                    {{ translate(transport.origin.street) }}
                                    {{ transport.origin.house_number }}{{ this.stringIfNull(transport.origin.house_number_addition) }}
                                    <strong>{{ t('in') }}</strong> {{ translate(transport.origin.city) }}
                                </div>
                            </div>
                            <div class="col-2">
                                <a class="navigate" @click="openMap(transport.origin)" href="javascript:void(0)">
                                    <i class="fa fa-location-arrow"></i>
                                </a>
                            </div>
                        </div>
                        <div v-for="(line, i) in transport.lines" :key="i" class="row cargo-item">
                            <div class="col-10">
                                {{ translate(line.waste_description) }}
                            </div>
                            <div class="col-2">
                                <span class="no-line-break">{{ formatWeight(line.planned_weight) }}</span>
                            </div>
                        </div>
                        <div class="cargo-item-footer">
                            <div class="row">
                                <div class="col-4">
                                    <span class="form-date-time">{{parseDateAndFormat(transport.planned_date)}} {{transport.planned_time}}</span>
                                </div>
                                <div class="col-5">
                                    <span class="form-number">{{transport.form_number}}</span>
                                </div>
                                <div class="col-3">
                                    <button class="btn btn-primary btn-xs" @click="openTransport(transport.id)">{{ t('Bekijk') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!transports.length">
                    <div class="row bg-white pt-3">
                        <div class="col-12">
                            <div class="text-center">
                                <p>{{t('Geen open transporten gevonden')}}</p>
                                <button class="btn btn-primary" @click="getTransports">{{ t('Verversen') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <current-load v-if="status === 'loaded' && !show_refused_page && !show_unload_page && !show_loaded_qrs"/>
            <refused-page v-if="status === 'loaded' && show_refused_page && !show_unload_page"/>
            <unload-page v-if="status === 'loaded' && show_unload_page && !show_refused_page"/>
            <loaded-q-r-codes v-if="show_loaded_qrs"/>
        </div>
    </div>
    <div v-if="!selected_transport && !selected_receiver" id="reload-button" class="sticky-bottom bg-white row fixed-to-bottom">
        <div class="col">
            <button class="btn btn-primary btn-block" @click="getTransports">{{ t('Verversen') }}</button>
        </div>
        <div v-if="status === 'loaded'" class="col">
            <button class="btn btn-default btn-block" @click="show_loaded_qrs = !show_loaded_qrs">{{ show_loaded_qrs ? t('Transporten') : t("QR's")  }}</button>
        </div>
        <div class="col">
            <button class="btn btn-secondary btn-block" @click="changeStatus">{{ status == 'open' ? t('Lading tonen') : t('Open')  }}</button>
        </div>
    </div>
    <div v-if="!selected_transport && selected_receiver && !$store.state.selected_loaded_transport" id="reload-button" class="sticky-bottom bg-white row fixed-to-bottom">
        <div class="col" v-if="!$store.state.show_refused_page && !$store.state.show_unload_page">
            <button class="btn btn-primary btn-block" @click="unsetSelectedReceiver">{{ t('Terug') }}</button>
        </div>
        <div class="col" v-if="!$store.state.show_refused_page && !$store.state.show_unload_page">
            <button class="btn btn-secondary  btn-block" v-if="$store.state.selected_transports.length > 0" @click="toggleRefusedPage">{{ t('Geweigerd') }}</button>
        </div>
        <div class="col" v-if="!$store.state.show_refused_page && !$store.state.show_unload_page">
            <button class="btn btn-success btn-block" v-if="$store.state.selected_transports.length > 0" @click="unloadTransports">{{ t('Lossen') }}</button>
        </div>
        <div class="col" v-if="$store.state.show_refused_page && !$store.state.show_unload_page">
            <button class="btn btn-primary btn-block" @click="backToTransportSelection">{{ t('Terug') }}</button>
        </div>
        <div class="col" v-if="$store.state.show_refused_page && !$store.state.show_unload_page">
            <button :disabled="!valid_for_refusal" class="btn btn-danger btn-block" @click="refuseSelectedTransports">{{ t('Weigeren') }}</button>
        </div>
    </div>
    <div v-if="$store.state.selected_loaded_transport" id="reload-button" class="sticky-bottom bg-white row fixed-to-bottom">
        <div class="col-4">
            <button class="btn btn-primary btn-block" @click="unsetSelectedLoadedTransport">{{ t('Terug') }}</button>
        </div>
        <div class="col-4">
            <button class="btn btn-secondary btn-block" @click="$store.dispatch('setShowSelectedLoadedTransportLines', true)">{{ t('Lading') }}</button>
        </div>
    </div>
    <footer-buttons v-if="selected_transport"/>
    <global-loader/>
</template>

<script>
import MainNav from "@/components/MainNav.vue";
import Agent from "@/agent";
import UtilitiesBar from "@/components/UtilitiesBar.vue";
import "@/translations";
import SingleTransport from "@/components/SingleTransport.vue";
import SortingBar from "@/components/SortingBar.vue";
import FooterButtons from "@/components/FooterButtons.vue";
import CurrentLoad from "@/components/CurrentLoad.vue";
import RefusedPage from "@/components/RefusedPage.vue";
import UnloadPage from "@/components/UnloadPage.vue";
import LoadedQRCodes from "@/components/LoadedQRCodes.vue";
import GlobalLoader from "@/components/GlobalLoader.vue";


export default {
    name: 'TransportListPage',

    components: {
        LoadedQRCodes,
        UtilitiesBar,
        MainNav,
        SingleTransport,
        SortingBar,
        FooterButtons,
        CurrentLoad,
        RefusedPage,
        UnloadPage,
        GlobalLoader,
    },

    data: () => ({
        show_loaded_qrs: false,
    }),

    computed: {
        status() {
            return this.$store.state.status;
        },
        open_transports() {
            return this.$store.state.open_transports;
        },
        loaded_transports() {
            return this.$store.state.loaded_transports;
        },
        transports() {
            let transports = this[`${this.status}_transports`];
            if (this.$store.state.search_term !== '') {
                transports = transports.filter((transport) => {
                    return transport.form_number.includes(this.$store.state.search_term) ||
                        transport.disposer.name.toLowerCase().includes(this.$store.state.search_term.toLowerCase()) ||
                        this.translate(transport.origin.street).toLowerCase().includes(this.$store.state.search_term.toLowerCase()) ||
                        this.translate(transport.origin.city).toLowerCase().includes(this.$store.state.search_term.toLowerCase()) ||
                        transport.lines.some((line) => {
                            return line.waste_description.toLowerCase().includes(this.$store.state.search_term.toLowerCase());
                        })
                });
            }
            return transports;
        },
        selected_transport() {
            return this.$store.state.selected_transport;
        },
        selected_receiver() {
            return this.$store.state.selected_receiver;
        },
        show_refused_page() {
            return this.$store.state.show_refused_page;
        },
        show_unload_page(){
            return this.$store.state.show_unload_page;
        },
        valid_for_refusal() {
            return this.$store.state.selected_transports.length > 0
            && this.$store.state.refused_action !== null
            && this.$store.state.refused_remark !== ''
            && this.$store.state.refused_remark !== null
            && this.$store.state.refused_remark.length > 5;
        }
    },

    methods: {
        unsetSelectedLoadedTransport() {
            if(this.$store.state.show_selected_loaded_transport_lines){
                this.$store.dispatch('setShowSelectedLoadedTransportLines', false);
            }else{
                this.$store.dispatch('setSelectedLoadedTransport', null);
            }

        },
        backToTransportSelection() {
            this.$store.dispatch('setShowRefusedPage', false);
            this.$store.dispatch('setRefusedRemark', null);
            this.$store.dispatch('setRefusedAction', null);
        },
        openTransport(transport_id) {
            Agent.Transport.getTransport(transport_id).then((response) => {
                this.$store.dispatch('setSelectedTransport', response.data);
                this.$store.dispatch('setStep', 1);
                this.$router.push(`/transports/${transport_id}`);
            }).catch((error) => {
                console.error(error);
                this.$swal({
                    title: 'Error',
                    text: error.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            });
        },
        getTransports() {
            this.updateAllTransportLists([this.status]);
        },
        handleRouteChange() {
            const id = this.$route.params.id;
            if (id && parseInt(id)) {
                this.openTransport(id);
            } else {
                this.$store.dispatch('setSelectedTransport', null);
            }
        },
        changeStatus() {
            if (this.status === 'open') {
                this.$store.dispatch('setStatus', 'loaded');
            } else {
                this.$store.dispatch('setStatus', 'open');
            }
            this.show_loaded_qrs = false;
        },
        unsetSelectedReceiver() {
            this.$store.dispatch('setSelectedReceiver', null);
            this.$store.dispatch('setShowRefusedPage', false);
            this.$store.dispatch('setSelectedTransports', []);
            this.$store.dispatch('setRefuseSelectedTransports', false);
            this.$store.dispatch('setTransportsToUnload', []);
        },
        toggleRefusedPage() {
            this.$store.dispatch('setShowRefusedPage', !this.show_refused_page);
        },
        refuseSelectedTransports(){
            this.$store.dispatch('setRefuseSelectedTransports', true);
        },
        unloadTransports(){
            this.$store.dispatch('setShowUnloadPage', true);
        },
    },

    mounted() {
        this.getTransports()
        this.handleRouteChange()
    },

    watch: {
        '$route': 'handleRouteChange',
        'status': 'getTransports',
        'show_refused_page': function(){
            if(this.show_refused_page){
                this.$store.dispatch('setShowRefusedPage', true);
            }else{
                this.$store.dispatch('setShowRefusedPage', false);
            }
        }
    }
}
</script>

<style>

#reload-button{
    padding: 5px;
    background-color: #f8f9fa;
    border-top: 1px solid #dee2e6;
}
#reload-button button {
    font-size: 13px;
    display: block;
    width: 100%;
}

#table-header .row {
    background-color: #f8f9fa;
    padding-top: 15px;
    border-bottom: 1px solid #dee2e6;
    font-size:14px;
    padding-bottom: 15px;
}

.transport-item {
    padding-top: 10px;
    padding-left:   10px;
    padding-right:  10px;
    font-size:14px;
    background: #dedede;
}

.transport-item:nth-last-of-type(1) {
    padding-bottom: 60px;
}

.cargo-item {
    padding-bottom: 5px;
    padding-left:10px;
    padding-top:5px;
    font-style: italic;
    font-size: 13px;
}

.no-line-break {
    white-space: nowrap;
}

.navigate {
    font-size: 1.2rem;
    padding:5px;
    border: 1px solid #ccc;
    width: 30px;
    height: 30px;
    display: block;
    text-decoration: none;
    text-align: center;
    border-radius: 50%;
}

.navigate i {
    display: block;
}

.cargo-item-footer {
    background-color: #f8f9fa;
    padding-top: 5px;
    padding-bottom: 5px;
    border-top: 1px solid #dee2e6;
    font-size: 13px;
}

.btn-xs{
    padding: 0.25rem 0.5rem;
    font-size: 0.7rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.cargo-item-footer .col-3 {
    text-align: right;
}

.form-number {
    display: block;
    padding-top:3px;
}

.form-date-time {
    font-weight: bolder;
    display: block;
    padding-top:3px;
    font-style: italic;
}

.transport-disposer-name {
    color: #ff6600;
}

.bg-color{
    background: #dedede;
}

.fixed-to-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999; /* optional, to ensure it appears above other elements */
}
</style>

