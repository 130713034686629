<template>
    <div class="signature-pad">
        <canvas ref="canvas" @mousedown="startDrawing" @mouseup="stopDrawing" @mousemove="draw" @touchstart="startDrawing" @touchend="stopDrawing" @touchmove="draw"></canvas>
        <button class="btn btn-danger btn-sm" @click="clearCanvas">{{t('Reset')}}</button>
    </div>
</template>

<script>
export default {
    name: 'SignaturePad',
    props: {
        signature: {
            type: String,
            default: null
        },
        role: {
            type: String,
            default: 'transporter'
        }
    },
    data() {
        return {
            drawing: false,
            context: null,
            lastX: 0,
            lastY: 0,
            savedImageData: null,
            originalImageSize: { width: 0, height: 0 },
        };
    },
    mounted() {
        this.initializeCanvas();
        window.addEventListener('resize', this.handleResize); // Add this line
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize); // Clean up
    },
    methods: {
        initializeCanvas() {
            const canvas = this.$refs.canvas;
            canvas.width = canvas.offsetWidth;
            canvas.height = canvas.width * 0.75;

            this.context = canvas.getContext('2d');

            if (this.savedImageData) {
                // Restore the image scaled to the new canvas size
                const image = new Image();
                image.onload = () => {
                    // Maintain aspect ratio of the original image
                    const scaleFactor = Math.min(
                        canvas.width / this.originalImageSize.width,
                        canvas.height / this.originalImageSize.height
                    );
                    const scaledWidth = this.originalImageSize.width * scaleFactor;
                    const scaledHeight = this.originalImageSize.height * scaleFactor;
                    this.context.drawImage(image, 0, 0, scaledWidth, scaledHeight);
                };
                image.src = this.savedImageData;
            }
        },

        handleResize() {
            this.savedImageData = this.$refs.canvas.toDataURL();
            this.originalImageSize.width = this.$refs.canvas.width;
            this.originalImageSize.height = this.$refs.canvas.height;

            // Re-initialize the canvas which will also restore the image
            this.initializeCanvas();
        },
        startDrawing(event) {
            event.preventDefault();  // Prevent default browser action
            this.drawing = true;
            [this.lastX, this.lastY] = this.getCursorPosition(event);
        },
        draw(event) {
            event.preventDefault();  // Prevent default browser action
            if (!this.drawing) return;
            const [x, y] = this.getCursorPosition(event);
            this.context.lineWidth = 2; // You can set your desired line width
            this.context.lineCap = 'round';
            this.context.strokeStyle = 'black';
            this.context.beginPath();
            this.context.moveTo(this.lastX, this.lastY);
            this.context.lineTo(x, y);
            this.context.stroke();
            [this.lastX, this.lastY] = [x, y];
        },
        getCursorPosition(event) {
            const { top, left } = this.$refs.canvas.getBoundingClientRect();
            let x, y;

            if (event.touches) {
                x = event.touches[0].pageX;
                y = event.touches[0].pageY;
            } else {
                x = event.pageX;
                y = event.pageY;
            }

            return [x - left, y - top];
        },
        clearCanvas() {
            this.context.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
            this.$emit(`signature-updated-${this.role}`, null);
        },
        stopDrawing() {
            this.drawing = false;
            this.context.beginPath();
            const base64String = this.$refs.canvas.toDataURL();
            this.savedImageData = base64String; // Save the image data after drawing
            this.originalImageSize.width = this.$refs.canvas.width;
            this.originalImageSize.height = this.$refs.canvas.height;
            this.$emit(`signature-updated-${this.role}`, base64String);
        }
    },
    watch: {
        signature(value) {
            if (value) {
                const image = new Image();
                image.src = value;
                image.onload = () => {
                    this.context.drawImage(image, 0, 0);
                    this.$emit(`signature-updated-${this.role}`, value);
                };
            }
        }
    }
};
</script>

<style>
.signature-pad {
    position: relative;
    border: 1px solid #ccc;
}
.signature-pad canvas {
    width: 100%;
    height: calc(100% * 0.75);
    display: block;
}



</style>
