<template>
    <div class="card p-2 mb-2">
        <div class="form-group">
            <label for="transporter_name">{{ t('Opmerking behorend bij transport') }}</label>
            <textarea class="form-control" rows="3" v-model="transport_remark"></textarea>
        </div>
    </div>
    <div class="card p-2 mb-2">
        <div class="form-group">
            <label for="transporter_name">{{ t('Handtekening vervoerder') }}</label>
            <div class="signature-wrapper">
                <signature-pad ref="signaturePad" @signature-updated-transporter="handleSignatureUpdate" :role="'transporter'" :signature="signature"></signature-pad>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-4 pe-0">
                <div class="form-group">
                    <input type="text" class="form-control form-control-sm" :placeholder="t('Voornaam')" v-model="transporter_first_name">
                </div>
            </div>
            <div class="col-3 pe-0">
                <div class="form-group">
                    <input type="text" class="form-control form-control-sm" :placeholder="t('Tussenvoegsel')" v-model="transporter_insertion">
                </div>
            </div>
            <div class="col-5">
                <div class="form-group">
                    <input type="text" class="form-control form-control-sm" :placeholder="t('Achternaam')" v-model="transporter_last_name">
                </div>
            </div>
            <div class="col-12 mt-4">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" checked v-model="save_transporter_data_for_next_transports" id="flexCheckIndeterminate">
                    <label class="form-check-label" for="flexCheckIndeterminate">
                        {{ t('Naam en handtekening onthouden voor volgende transporten') }}
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SignaturePad from "@/components/SignaturePad.vue";

export default {
    name: 'TransporterSignPage',
    components: {
        SignaturePad
    },
    data: () => ({
        transport_remark: '',
        transporter_first_name: '',
        transporter_last_name: '',
        transporter_insertion: '',
        signature: null,
        save_transporter_data_for_next_transports: true
    }),
    methods: {
        handleSignatureUpdate(base_64) {
            this.$store.dispatch('setTransporterSignature', base_64);
            this.signature = base_64;
        }
    },
    watch: {
        'transport_remark': function (val) {
            this.$store.dispatch('setTransportRemark', val);
        },
        'transporter_first_name': function (val) {
            this.$store.dispatch('setTransporterFirstName', val);
        },
        'transporter_last_name': function (val) {
            this.$store.dispatch('setTransporterLastName', val);
        },
        'transporter_insertion': function (val) {
            this.$store.dispatch('setTransporterInsertion', val);
        },
        'save_transporter_data_for_next_transports': function (val) {
            if(val){
                this.$store.dispatch('setSaveTransporterDataForNextTransports', {
                    first_name: this.transporter_first_name,
                    last_name: this.transporter_last_name,
                    insertion: this.transporter_insertion,
                    signature: this.signature
                });
            }else{
                this.$store.dispatch('setSaveTransporterDataForNextTransports', {});
            }
        }
    },

    mounted() {
        const transporter_data = this.transporterData();
        this.transporter_first_name = transporter_data.first_name;
        this.transporter_last_name = transporter_data.last_name;
        this.transporter_insertion = transporter_data.insertion;
        this.signature = transporter_data.signature ? transporter_data.signature : null;

        this.save_transporter_data_for_next_transports = this.$store.state.save_transporter_data_for_next_transports;

        if(this.$store.state.transport_fields_update_values.transport_remark) {
            this.transport_remark = this.$store.state.transport_fields_update_values.transport_remark;
        }
        if(this.$store.state.transport_fields_update_values.transporter_first_name) {
            this.transporter_first_name = this.$store.state.transport_fields_update_values.transporter_first_name;
        }
        if(this.$store.state.transport_fields_update_values.transporter_last_name) {
            this.transporter_last_name = this.$store.state.transport_fields_update_values.transporter_last_name;
        }
        if(this.$store.state.transport_fields_update_values.transporter_insertion) {
            this.transporter_insertion = this.$store.state.transport_fields_update_values.transporter_insertion;
        }
        if(this.$store.state.transport_fields_update_values.transporter_signature) {
            this.signature = this.$store.state.transport_fields_update_values.transporter_signature;
        }
        if(this.$store.state.save_transporter_data_for_next_transports){
            this.save_transporter_data_for_next_transports = this.$store.state.save_transporter_data_for_next_transports;
        }
    },
}

</script>

<style scoped>

</style>