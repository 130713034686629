import store from "@/store";


const axios = require('axios')

const Cookies = {
    getCookie: (name) => {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            let cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    },

    setCookie: (name, value) => {
        let date = new Date();
        date.setTime(date.getTime() + (10 * 365 * 86400000));
        let expires = "; expires=" + date.toUTCString();
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }
}

let axiosInitHeaders = {
    accept: 'application/json',
    'X-Api-Token': Cookies.getCookie('x-api-token')
}

// load baseurl from env

const Axios = axios.create({
    headers: axiosInitHeaders,
    baseURL: process.env.VUE_APP_API_BASE_URL,
});

Axios.defaults.withCredentials = false

Axios.interceptors.request.use(
    config => {
        if (!navigator.onLine) {
            return Promise.reject({
                response: {
                    data: {
                        message: 'No internet connection'
                    }
                }
            });
        }
        const token = Cookies.getCookie('x-api-token');
        if (token) {
            config.headers['X-Api-Token'] = token;
        }
        store.dispatch('setIsLoading', true);
        return config;
    },
    error => {
        store.dispatch('setIsLoading', false);
        Promise.reject({
            response: {
                data: {
                    message: error
                }
            }
        });
    },
);

Axios.interceptors.response.use(
    response => {
        // Stop the loading when a response is received
        store.dispatch('setIsLoading', false);
        return response;
    },
    async function (error) {
    if (error) {
        store.dispatch('setIsLoading', false);
        const { config, response } = error;
        if (axios.isCancel(error)){
            return new Promise(() => {});
        }
        if (response && response.status === 401) {
            // empty localstorage
            localStorage.clear();
            //clean all caches
            const keys = await caches.keys();
            keys.forEach(key => caches.delete(key));
            //delete all cookies
            document.cookie.split(";").forEach((c) => {
                document.cookie = c
                    .replace(/^ +/, "")
                    .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            });
            // delete all cookies
            Cookies.setCookie('x-api-token', '');

            window.location.href = '/#/login';
        }
        return Promise.reject(error);
    }
})

const progress = {
    header : {
        'Content-Type' : 'multipart/form-data'
    },
    onUploadProgress(progressEvent) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    }
}

const Mailbox = {
    requestCode: data => {
        return Axios.post(`/mailbox/get_code`, data)
    },
    requestToken: data => {
        return Axios.post(`/mailbox/get_token`, data)
    },
}

const Transport = {
    getTransport(transport_id) {
        return Axios.get(`/transports/${transport_id}`)
    },
    getTransports: status => {
        return Axios.get(`/transports/${status}`)
    },
    sendEmail(email_address, transport_ids, language) {
        return Axios.post(`/transports/send_email`, {
            email_address: email_address,
            transport_ids: transport_ids,
            language: language
        })
    },
    getContacts: (transport_id, disposer_id) => {
        return Axios.get(`/transports/${transport_id}/relation/${disposer_id}/contacts`)
    },
    startTransport: (payload) => {
        return Axios.post(`/transports/${payload.transport_id}/start`, payload)
    },
    refuseTransports(payload) {
        return Axios.post(`/transports/refuse`, payload)
    },
    endTransports(payload) {
        return Axios.post(`/transports/end`, payload)
    }
}

export default {
    Cookies: Cookies,
    Mailbox: Mailbox,
    Transport: Transport
}