import { createApp } from 'vue'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import LoginPage from "@/components/LoginPage.vue";
import * as VueRouter from 'vue-router'
import store from "@/store";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import TransportListPage from "@/components/TransportListPage.vue";
import MapPage from "@/components/MapPage.vue";
import translationsMixin from './mixins/translationsMixin.js';
import utilsMixin from "@/mixins/utilsMixin";
import transportUpdaterMixin from "@/mixins/transportUpdaterMixin.vue";
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: 'https://04d456cdab5149a8a7fd9622374d79df@glitchtip.sedna.software/14',
  integrations: [Sentry.reportingObserverIntegration()],
});

// Adding manifest and meta for theme-color
const link = document.createElement('link');
link.rel = 'manifest';
link.href = '/manifest.json';
document.head.appendChild(link);

const meta = document.createElement('meta');
meta.name = 'theme-color';
meta.content = '#4DBA87';
document.head.appendChild(meta);

// Registering the service worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js').then(registration => {
            console.log('SW registered: ', registration);

            // Check if a new service worker is waiting to be activated
            if (registration.waiting) {
                registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                window.location.reload();
            }

            // Listen for updates to the service worker.
            registration.addEventListener('updatefound', () => {
                const newWorker = registration.installing;
                newWorker.addEventListener('statechange', () => {
                    if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
                        // New update available
                        console.log('New service worker available.');
                        // Optionally, inform the user and refresh the page
                    }
                });
            });

        }).catch(registrationError => {
            console.log('SW registration failed: ', registrationError);
        });
    });
}

const routes = [
    { path: '/login', component: LoginPage, name: 'login', meta: { title: 'Login' }},
    { path: '/transports/', component: TransportListPage, name: 'transportlist', meta: { title: 'Transports' } },
    { path: '/transports/:id', component: TransportListPage, name: 'single_transport', meta: { title: 'Transport' } },
    { path: '/map', component: MapPage, name: 'map', meta: { title: 'Map' } },
]

const router = VueRouter.createRouter({
    history: VueRouter.createWebHashHistory(),
    routes: routes
})

const mailbox = localStorage.getItem('mailbox');
if (mailbox && mailbox !== null) {
    /* eslint-disable no-useless-escape */
    const re = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    if (!re.test(mailbox)) {
        localStorage.removeItem('mailbox');
    }else{
        store.dispatch('setMailbox', mailbox);
    }
}

const user = localStorage.getItem('user');
if (typeof user === 'object' &&
    !Array.isArray(user) &&
    user !== null) {
    store.dispatch('setUser', JSON.parse(user));
}else{
    localStorage.removeItem('user');
}

router.beforeEach((to, from, next) => {
    const isLoggedIn = store.state.mailbox !== null;
    if (!isLoggedIn && to.name !== 'login'){
        next({ name: 'login' });
    } else {
        if (to.path === '/')  {
            next({ name: 'transportlist' });
        }
        next();  // Allow access
    }
});

const swal_options = {
    confirmButtonColor: '#41b882',
    cancelButtonColor: '#ff7674',
};

const app = createApp(App)
app.mixin(translationsMixin)
app.mixin(utilsMixin)
app.mixin(transportUpdaterMixin)
app.use(router)
app.use(store)
app.use(VueSweetalert2, swal_options);
app.mount('#app')