<template>
    <nav class="navbar navbar-expand-lg">
        <div class="container-fluid">
            <a class="navbar-brand" @click="goToHome">
                <img src="../assets/formdesk_logo.png" alt="FormDesk logo" style="width: 150px">
            </a>
            <a id="language_switcher" @click="show_language_switcher = !show_language_switcher">
                <country-flag v-if="language === 'nl'" country="nl" size="normal" />
                <country-flag v-if="language === 'en'" country="gb" size="normal" />
                <country-flag v-if="language === 'fr'" country="fr" size="normal" />
                <ul v-if="show_language_switcher" id="language_select">
                    <li v-if="language !== 'nl'" @click="language = 'nl'">
                        <country-flag country="nl" size="normal" />
                    </li>
                    <li v-if="language !== 'en'" @click="language = 'en'">
                        <country-flag country="gb" size="normal" />
                    </li>
                    <li v-if="language !== 'fr'" @click="language = 'fr'">
                        <country-flag country="fr" size="normal" />
                    </li>
                </ul>
            </a>
            <a><internet-monitor /></a>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent" style="text-align: right">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <a class="nav-link" @click="doLogout">{{ t('Uitloggen') }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
    import CountryFlag from "/node_modules/vue-country-flag-next";
    import InternetMonitor from "@/components/InternetMonitor.vue";

    export default {
        name: 'MainNav',

        components: {
            InternetMonitor,
            CountryFlag
        },

        data: () => ({
            language: 'nl',
            show_language_switcher: false
        }),

        methods: {
            goToHome() {
                this.$router.push({ name: 'transportlist' });
            },
            doLogout() {
                this.$store.dispatch('logout');
                this.$router.push({ name: 'login' });
            },
        },

        watch: {
            language: function(newValue) {
                this.$store.dispatch('setLanguage', newValue);
            }
        },

        mounted() {
            this.language = this.$store.state.language;
        }

    }
</script>

<style scoped>
#language_select {
    border:none;
}

#language_switcher{
    position: relative;
    background: none;
    border: none;
}

#language_select {
    left:-50%;
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    position: absolute;
    background: white;
    z-index:1000
}

#language_select li {
    list-style: none;
    padding: 5px;
}

</style>