<template>
    <div id="loaded_qr_codes">
        <div v-for="(transport, index) in $store.state.loaded_transports" :key="index" class="qr-code-container">
            <strong>{{transport.form_number}}</strong>
            <qr-code-generator :inputUrl="transport.document_url" />
        </div>
    </div>
</template>

<script>

import QRCodeGenerator from "@/components/QRCodeGenerator.vue";
export default {
    name: 'LoadedQRCodes',

    components: {
        'qr-code-generator': QRCodeGenerator
    },

    computed: {

    },

}
</script>

<style>

#loaded_qr_codes{
    background: #fff;
    padding: 10px;
}

.qr-code-container {
    width: 100%;
    padding-bottom: 40px;
}

.qr-code-container strong {
    display: block;
    text-align: center;
}

.qr-code-container img {
    width: 100%;
}


@media (min-width: 512px) {
    #loaded_qr_codes{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

</style>
