<template>
    <div class="container-fluid">
        <div class="row">
            <div id="login-bg">
                <div class="text-center img img-fluid mb-5">
                    <img id="logo" src="../assets/formdesk_logo.png" alt="FormDesk logo" >
                </div>
                <div v-if="current_step == 'email'" id="login-select-method">
                    <h4>{{t('Vul uw e-mailadres in')}}</h4>
                    <div class="form-group">
                        <input ref="emailInput" type="email" class="form-control" id="email" v-model="email">
                        <a v-if="email" id="clear_email" @click="email = ''">
                            <i class="fa fa-close"></i>
                        </a>
                    </div>
                    <div v-if="!is_loading" class="form-group">
                        <button :disabled="!emailIsValid" id="login-submit-email" class="btn btn-primary form-control" @click="submitEmail">{{t('Code opvragen')}}</button>
                    </div>
                </div>
                <div v-else id="login-select-method">
                    <h4>{{t('Vul hier de code uit uw mailbox in')}}</h4>
                    <div class="form-group">
                        <input ref="codeInput" type="number" maxlength="6" class="form-control" id="code" v-model="code">
                    </div>
                    <div v-if="!is_loading">
                        <div class="form-group">
                            <button :disabled="!codeIsValid" id="login-submit-code" class="btn btn-primary form-control" @click="submitEmailAndCode">{{t('Inloggen')}}</button>
                        </div>
                        <div class="form-group">
                            <button id="login-submit-code-back" class="btn btn-cancel form-control" @click="current_step = 'email'">{{t('Terug')}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <div class="text-center">
                    © <span>{{new Date().getFullYear()}}</span> <a href="https://sedna.software">SEDNA</a><span>.software</span> -
                    {{t('versie')}}: {{version}}
                </div>
            </footer>
        </div>
    </div>
    <global-loader/>
</template>

<script>
import Agent from "@/agent";
import pkg from '/package.json';
import GlobalLoader from "@/components/GlobalLoader.vue";

export default {
    name: 'LoginPage',
    components: {GlobalLoader},

    data: () => ({
        email: '',
        code: '',
        current_step: 'email',
        version: pkg.version
    }),

    methods: {
        submitEmail() {
            Agent.Mailbox.requestCode({email:this.email}).then(
                response => {
                    this.current_step = 'code';
                }
            ).catch(
                error => {
                    this.$swal({
                        title: 'Error',
                        text: error.response.data.message,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    });
                }
            );
        },
        submitEmailAndCode() {
            Agent.Mailbox.requestToken({
                email: this.email,
                code: this.code
            }).then(
                response => {
                    Agent.Cookies.setCookie('x-api-token', response.data.access_token);
                    Agent.Cookies.setCookie('email-address', this.email);
                    this.$store.dispatch('setMailbox', this.email);
                    this.$store.dispatch('setUser', response.data.user);
                    localStorage.setItem('mailbox', this.email);
                    localStorage.setItem('user', JSON.stringify(response.data.user));
                    this.$router.push({ name: 'transportlist' });
                }
            ).catch(
                error => {
                    this.$swal({
                        title: 'Error',
                        text: error.response.data.message,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    });
                }
            );
        },
    },

    watch: {
        'current_step': function(newStep){
            this.$nextTick(() => {
                if (newStep === 'email') {
                    this.$refs.emailInput.focus();
                } else {
                    this.$refs.codeInput.focus();
                }
            });
        },
    },

    computed: {
        codeIsValid() {
            return this.code.toString().length === 6 && !isNaN(this.code);
        },
        emailIsValid() {
            return this.validateEmail(this.email);
        },
        is_loading() {
            return this.$store.state.is_loading;
        }
    },
    mounted() {
        this.$refs.emailInput.focus();
        const email_from_cookie = Agent.Cookies.getCookie('email-address');
        this.email = email_from_cookie ? email_from_cookie : '';
    }
}
</script>


<style scoped>

html, body{
    height: 100vh;
}

#login-bg {
    background-image: url('../assets/bottom-login.png');
    background-position: bottom;
    background-repeat: no-repeat;
    height: calc(100vh - 60px);
}

#login-select-method {
    background-color: #f9f9f9;
    width: 50%;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
}

#logo {
    width: 200px;
    margin-top: 100px;
}

#login-submit-email {
    background-color: #2c3e50;
    border-color: #2c3e50;
    margin-top:10px;
}

#login-submit-code {
    margin-top:10px;
}

#login-submit-code-back {
    margin-top:10px;
}

#code {
    text-align: center;
    font-size: 20px;
    letter-spacing: 10px;
}

h4 {
    text-align: center;
}

#login-select-method .form-group {
    position: relative;
}

#email {
    padding-right:25px;
}

#clear_email {
    position: absolute;
    right: 15px;
    top: 8px;
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}


@media (max-width: 768px) {
    #login-select-method {
        width: 75%;
    }
}


@media (max-width: 420px) {
    #login-select-method {
        width: 100%;
    }
}

</style>