<template>
    <div id="table-header">
        <div class="row">
            <div class="col">
                {{ t('Sorteer op') }}
            </div>
            <div class="col text-nowrap" @click="changeSortingSettings('date')">
                {{ t('Datum') }}
                <span class="sorting-chevrons">
                                <i v-if="sorting_settings.date === 'asc'" class="fa fa-chevron-up"></i>
                                <i v-else-if="sorting_settings.date === 'desc'" class="fa fa-chevron-down"></i>
                                <i v-else class="fa fa-minus"></i>
                            </span>
            </div>
            <div class="col text-nowrap"  @click="changeSortingSettings('place')">
                {{ t('Plaats') }}
                <span class="sorting-chevrons">
                                <i v-if="sorting_settings.place === 'asc'" class="fa fa-chevron-up"></i>
                                <i v-else-if="sorting_settings.place === 'desc'" class="fa fa-chevron-down"></i>
                                <i v-else class="fa fa-minus"></i>
                            </span>
            </div>
            <div class="col text-nowrap" @click="changeSortingSettings('relation')">
                {{ t('Relatie') }}
                <span class="sorting-chevrons">
                                <i v-if="sorting_settings.relation === 'asc'" class="fa fa-chevron-up"></i>
                                <i v-else-if="sorting_settings.relation === 'desc'" class="fa fa-chevron-down"></i>
                                <i v-else class="fa fa-minus"></i>
                            </span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'SortingBar',

    data: () => ({
        sorting_settings: {
            date: 'desc',
            place: '',
            relation: ''
        }
    }),

    methods: {
        getActiveSettingName() {
            for (let key in this.sorting_settings) {
                if (this.sorting_settings[key] !== '') {
                    return key;
                }
            }
            return '';
        },
        changeSortingSettings(setting_name) {
            let current_sorting = this.sorting_settings[setting_name];

            let allOthersEmpty = true;
            for (let key in this.sorting_settings) {
                if (key !== setting_name && this.sorting_settings[key] !== '') {
                    allOthersEmpty = false;
                    break;
                }
            }

            for (let key in this.sorting_settings) {
                this.sorting_settings[key] = '';
            }

            if (current_sorting === '' || (allOthersEmpty && current_sorting === 'desc')) {
                this.sorting_settings[setting_name] = 'asc';
            } else if (current_sorting === 'asc') {
                this.sorting_settings[setting_name] = 'desc';
            }
        },
        doSorting() {
            let active_Setting = this.getActiveSettingName();
            const originalTransports = this.$store.state[`${this.$store.state.status}_transports`];
            const sortedTransports = originalTransports.sort((a, b) => {
                if(active_Setting === 'place') {
                    const place_1 = this.translate(a.origin.city);
                    const place_2 = this.translate(b.origin.city);

                    if (place_1 < place_2) {
                        return this.sorting_settings.place === 'asc' ? 1 : -1;
                    }
                    if (place_1 > place_2) {
                        return this.sorting_settings.place === 'asc' ? -1 : 1;
                    }
                    return 0;
                }
                if(active_Setting === 'relation') {
                    const relation_1 = a.disposer.name;
                    const relation_2 = b.disposer.name;

                    if (relation_1 < relation_2) {
                        return this.sorting_settings.relation === 'asc' ? 1 : -1;
                    }
                    if (relation_1 > relation_2) {
                        return this.sorting_settings.relation === 'asc' ? -1 : 1;
                    }
                    return 0;
                }
                if (active_Setting === 'date') {

                    const date_1 = new Date(a.planned_date);
                    const date_2 = new Date(b.planned_date);

                    const time_1 = a.planned_time.split(':').length == 2 ? a.planned_time.split(':') : ['23', '59'];
                    const time_2 = b.planned_time.split(':').length == 2 ? b.planned_time.split(':') : ['23', '59'];

                    date_1.setHours(time_1[0]);
                    date_1.setMinutes(time_1[1]);
                    date_2.setHours(time_2[0]);
                    date_2.setMinutes(time_2[1]);

                    if (date_1 < date_2) {
                        return this.sorting_settings.date === 'asc' ? 1 : -1;
                    }
                    if (date_1 > date_2) {
                        return this.sorting_settings.date === 'asc' ? -1 : 1;
                    }
                    return 0;
                }
            });
            this.$store.dispatch(`set${this.firstLetterUppercase(this.status)}Transports`, sortedTransports);
        }
    },

    computed: {
        status() {
            return this.$store.state.status;
        }
    },

    watch: {
        sorting_settings: {
            handler() {
                this.doSorting();
            },
            deep: true
        },
        '$store.state.open_transports': {
            handler() {
                this.doSorting();
            },
            deep: true
        },
        '$store.state.loaded_transports': {
            handler() {
                this.doSorting();
            },
            deep: true
        },
    },
    mounted() {
        this.doSorting();
    }
}


</script>

<style scoped>
#table-header .row {
    background-color: #f8f9fa;
    padding-top: 15px;
    border-bottom: 1px solid #dee2e6;
    font-size:14px;
    padding-bottom: 15px;
}
</style>