<template>
    <div ref="mapContainer" class="map-container"></div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import longTouch from "ol-ext/interaction/LongTouch";
mapboxgl.accessToken = 'pk.eyJ1IjoicmljazE0MTU5IiwiYSI6ImNsbzQyd3FoeDFjZ28yaXBqcjdteHM3bHUifQ.Q0eCR_rsYMYW49L0huNxHg'

export default {
    name: 'MapBox',
    data: () => ({
        map: null,
        marker: null,
        popup: null,
    }),
    methods: {
        centerMap() {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                this.map.setCenter([longitude, latitude]);
            });
        },
    },
    mounted() {
        // Initialize Map
        const map = new mapboxgl.Map({
            container: this.$refs.mapContainer,
            style: 'mapbox://styles/mapbox/streets-v12',
            zoom: 16,
            interactive: false
        });

        // Initialize Popup
        const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });


        // Use HTML5 Geolocation API to get current position
        navigator.geolocation.getCurrentPosition((position) => {
            let { latitude, longitude } = position.coords;
            if(this.$store.state.transport_fields_update_values.start_gps) {
                latitude = this.$store.state.transport_fields_update_values.start_gps.lat
                longitude = this.$store.state.transport_fields_update_values.start_gps.lng
            }

            // Set map center to current position
            map.setCenter([longitude, latitude]);

            // Place a marker at the current position
            const marker = new mapboxgl.Marker()
                .setLngLat([longitude, latitude])
                .setPopup(popup)
                .addTo(map);

            this.marker = marker;
            this.popup = popup;

            if (this.popup) {
                this.popup.setLngLat([longitude, latitude])
                    .setHTML(`<p>Lat: ${latitude.toFixed(4)}, Lon: ${longitude.toFixed(4)}</p>`)
                    .addTo(map);
            }

        }, (error) => {
            console.error('Unable to fetch the current location', error);
        });

        map.on('move', () => {
            const newCenter = map.getCenter();
            this.$emit('new-gps', newCenter);
            if (this.marker) {
                this.marker.setLngLat([newCenter.lng, newCenter.lat]);
            }
            // Hide popup while moving
            if (this.popup) {
                this.popup.remove();
            }
        });

        map.on('moveend', () => {
            const newCenter = map.getCenter();
            this.$emit('new-gps', newCenter);
            if (this.popup) {
                this.popup.setLngLat([newCenter.lng, newCenter.lat])
                    .setHTML(`<p>Latitude: ${newCenter.lat.toFixed(4)}, Longitude: ${newCenter.lng.toFixed(4)}</p>`)
                    .addTo(map);
            }
        });

        this.map = map;
    },
    unmounted() {
        if (this.map) {
            this.map.remove();
            this.map = null;
        }
        if (this.marker) {
            this.marker.remove();
            this.marker = null;
        }
        if (this.popup) {
            this.popup.remove();
            this.popup = null;
        }
    },
};

</script>

<style scoped>
.map-container {
    width: 100%;
    height: 100%;
}

</style>