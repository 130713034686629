import t from '@/translations.js';
import store from '@/store';

export default {
    data() {
        return {
            default_language: 'nl'
        }
    },

    methods: {
        t(key) {
            return t(key, store);
        },
        translate(data, default_return_value = null) {
            if(Object.prototype.toString.call(data) === '[object Object]' ) {
                if (data && store.state.language in data) {
                    return data[store.state.language];
                } else if (data && this.default_language in data) {
                    return data[this.default_language];
                }
            }
            if(default_return_value) {
                return default_return_value;
            }
            return data;
        }
    }
};