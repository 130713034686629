export default {
    methods: {
        formatWeight(weight) {
            if(weight > 999){
                return (parseInt(weight) /  1000).toFixed(2) + ` ${this.t('t')}`
            }else {
                return parseInt(weight) + ` ${this.t('kg')}`
            }
        },
        stringIfNull(value) {
            if (value === null) {
                return '';
            }
            return value;
        },
        parseDateAndFormat(date) {
            date = new Date(date);

            const options = {
                weekday: "short",
                month: "short",
                day: "numeric"
            };
            return date.toLocaleDateString(this.$store.state.language, options);
        },
        firstLetterUppercase(v) {
            return v.charAt(0).toUpperCase() + v.slice(1);
        },
        validateEmail(email) {
            /* eslint-disable no-useless-escape */
            const emailRegex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
            return emailRegex.test(email)
        },
        phoneNumberLink(phoneNumber) {
            if(phoneNumber === null) {
                return '';
            }
            return `<a href="tel:${phoneNumber}">${phoneNumber}</a>`;
        },
        formatName(firstname, lastname, lastname_prefix) {
            if(lastname_prefix) {
                return `${this.firstLetterUppercase(firstname)} ${lastname_prefix} ${this.firstLetterUppercase(lastname)}`;
            }
            return `${this.firstLetterUppercase(firstname)} ${this.firstLetterUppercase(lastname)}`;
        },
        transporterData(){
            const returnUser = () => {
                if(this.$store.state.user && this.$store.state.user.lastname) {
                    return {
                        first_name: this.$store.state.user.firstname,
                        last_name: this.$store.state.user.lastname,
                        insertion: this.$store.state.user.lastname_prefix,
                    }
                }
                return {
                    first_name: null,
                    last_name: null,
                    insertion: null,
                }
            }

            const data = localStorage.getItem('transporter_data');
            try {
                let parsed = JSON.parse(data);
                if(Object.keys(parsed).length > 0 && parsed.constructor === Object) {
                    return parsed;
                }
            }
            catch (e) {
                return returnUser();
            }
            return returnUser();
        },
        totalWeightOfTransport(transport) {
            let total = 0;
            transport.lines.forEach((item) => {
                total += parseInt(item.received_weight);
            });
            return total;
        },
        async getCurrentLocation() {
            return new Promise((resolve, reject) => {
                if ("geolocation" in navigator) {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            resolve(position.coords);
                        },
                        (error) => {
                            console.error("Error fetching location: ", error);
                            reject(error);
                        }
                    );
                } else {
                    const errorMsg = "Geolocation not available on this device.";
                    console.error(errorMsg);
                    reject(new Error(errorMsg));
                }
            });
        },
        async openMap(address) {
            try {
                let destinationAddress = `${this.translate(address.street)} ${address.house_number}${this.stringIfNull(address.house_number_addition)}, ${this.translate(address.city)}`;
                const coords = await this.getCurrentLocation();

                if (coords.latitude && coords.longitude) {
                    const url = `https://maps.apple.com/?daddr=${destinationAddress}&saddr=${coords.latitude},${coords.longitude}`
                    window.open(url, "_blank");

                } else {
                    console.error("Cannot open Google Maps without valid latitude and longitude.");
                }
            } catch (error) {
                console.error("An error occurred:", error);
            }
        },
    },
};