<template>
    <div id="footer-buttons" class="sticky-bottom bg-white fixed-to-bottom">
        <div v-if="$store.state.unload_step === 1" class="row button-row">
            <div class="col button-col">
                <div class="button-wrapper">
                    <button class="btn btn-secondary" @click="unsetStep">{{ t('Terug') }}</button>
                </div>
            </div>
            <div class="col button-col text-center">
            </div>
            <div class="col button-col text-end">
                <div class="button-wrapper">
                    <button class="btn btn-success" :disabled="!gps_retrieved" @click="step(2)">{{ t('Verder') }}</button>
                </div>
            </div>
        </div>
        <div v-if="$store.state.unload_step === 2">
            <div v-for="x in Object.keys(manager)" :key="x" class="row button-row" v-show="manager[x]">
                <div class="col button-col">
                    <div class="button-wrapper">
                        <button class="btn btn-secondary" @click="handleTransport(parseInt(x) - 1)">{{ t('Terug') }}</button>
                    </div>
                </div>
                <div class="col button-col text-center">
                    <div class="button-wrapper">
                        <span>{{ (parseInt(x) + 1) + ' ' + t('van') + ' ' + $store.state.transports_to_unload.length }}</span>
                    </div>
                </div>
                <div class="col button-col text-end">
                    <div class="button-wrapper">
                        <button class="btn btn-success" @click="handleTransport(parseInt(x) + 1)">{{ t('Verder') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="$store.state.unload_step === 3">
            <div class="row button-row">
                <div class="col button-col">
                    <div class="button-wrapper">
                        <button class="btn btn-secondary" @click="step(2)">{{ t('Terug') }}</button>
                    </div>
                </div>

                <div class="col button-col text-end">
                    <div class="button-wrapper">
                        <button class="btn btn-success" @click="endTransport()">{{ t('Beëindigen') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    name: 'UnloadFooterButtons',

    data: () => ({
        manager: {},
        step_titles: {}
    }),

    methods: {
        unsetStep() {
            this.$store.dispatch('setUnloadStep', null);
            this.$store.dispatch('setPageHeader', this.t(''))
            this.$store.dispatch('setShowUnloadPage', false);
            this.$store.dispatch('setTransportsToUnload', []);
        },
        step(step) {
            this.$store.dispatch('setUnloadStep', step);
            this.$store.dispatch('setPageHeader', this.t('Stap') + ` ${step}/3` + ' - ' + this.step_titles[step])
            if(step === 1){
                this.$store.dispatch('setUnloadStep', null);
            }
        },
        endTransport() {
            this.$store.dispatch('setDoEndTransport', true);
        },
        handleTransport(index) {
            if(index < 0){
                this.unsetStep()
            }
            if(index >= this.$store.state.transports_to_unload.length){
                this.step(3)
            }
            if(index in this.manager) {
                for (let key in this.manager) {
                    this.manager[key] = key == index;
                }
            }
        }
    },

    watch: {
        'manager': {
            handler() {
                this.$store.dispatch('setUnloadingTransportsManager', this.manager);
            },
            deep: true
        },
        '$store.state.unloading_transports_manager': {
            handler() {
                this.manager = this.$store.state.unloading_transports_manager;
            },
            deep: true
        },
    },

    computed: {
        gps_retrieved() {
            return this.$store.state.transport_fields_update_values.end_gps && Object.keys(this.$store.state.transport_fields_update_values.end_gps).length > 0;
        }
    },

    mounted() {
        this.step_titles = {
            1: this.t('Selecteer loslocatie'),
            2: this.t('Ontvangen gewichten en aantallen'),
            3: this.t('Handtekening verwerker'),
        }
    }


}

</script>

<style scoped>
#footer-buttons{
    padding: 5px;
    background-color: #f8f9fa;
    border-top: 1px solid #dee2e6;
}
.button-row {
    display: flex;
    justify-content: center;
    align-items: center;
}
.button-col {
    display: flex;
    justify-content: center;
    align-items: center;
}
.button-wrapper {
    width: 100%;
}
#footer-buttons button {
    font-size: 13px;
    width: 100%;
}

.fixed-to-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999; /* optional, to ensure it appears above other elements */
}
</style>
