<template>
    <div v-if="$store.state.unload_step === 1">
        <map-page callback="setEndGPS" />
    </div>
    <div v-if="$store.state.unload_step === 2">
        <unload-cargo-page/>
    </div>
    <div v-if="$store.state.unload_step === 3" id="single-transport-view">
        <receiver-sign-page/>
    </div>
    <teleport to="#app">
        <unload-footer-buttons/>
    </teleport>
</template>

<script>
import Agent from "@/agent";
import MapPage from "@/components/MapPage.vue";
import UnloadFooterButtons from "@/components/UnloadFooterButtons.vue";
import UnloadCargoPage from "@/components/UnloadCargoPage.vue";
import ReceiverSignPage from "@/components/ReceiverSignPage.vue";

export default {
    name: 'UnloadPage',
    components: {MapPage, UnloadFooterButtons, UnloadCargoPage, ReceiverSignPage},
    data: () => ({
        transports_to_unload: [],
    }),
    watch: {

    },
    computed: {

    },
    methods: {

    },
    mounted() {
        if(this.$store.state.selected_transports){
            this.transports_to_unload = this.$store.state.loaded_transports.filter(transport => this.$store.state.selected_transports.indexOf(transport.id) !== -1);
            this.$store.dispatch('setTransportsToUnload', this.transports_to_unload);
        }
        this.$store.dispatch('setUnloadStep', 1);
        this.$store.dispatch('setPageHeader', this.t('Stap 1/3 - Selecteer loslocatie'));
    },
}

</script>

<style>



</style>