<template>
    <div v-if="!show_cargo_for_transport">
        <div class="col-12 card p-2">
            <div class="row">
                <div class="col-9">
                    {{ (transport.form_number ? transport.form_number : '') }}
                </div>
                <div class="col-3 align-self-center text-end">
                    <a class="send-email" href="javascript:void(0)" @click="show_mail_dialog = !show_mail_dialog">
                        <i class="fa fa-envelope"></i>
                    </a>
                </div>
                <div v-if="show_mail_dialog" class="col-12">
                    <div class="form-inline">
                        <div class="form-group d-inline-block mr-2">
                            <input v-model="email_address" class="form-control form-control-sm" placeholder="Email"
                                   type="email">
                        </div>
                        <div class="form-group d-inline-block ms-1">
                            <button :disabled="!emailIsValid"
                                    class="btn btn-primary btn-sm"
                                    type="button"
                                    @click="sendEmail(email_address, transport.id)">
                                {{ t('Verstuur') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 card p-2 mt-2">
            <div class="row">
                <div class="col-12">
                    <strong>{{ t('Afvalstoffenproducent') }}</strong>
                </div>
                <div class="col-12">
                    <div>
                        {{ translate(transport.disposer.name) }}<br/>
                        {{ translate(transport.disposer.address.street) }} {{ transport.disposer.address.house_number }}{{ this.stringIfNull(transport.disposer.address.house_number_addition) }}<br/>
                        {{ translate(transport.disposer.address.postal_code) }} {{ translate(transport.disposer.address.city) }}<br/>
                        <div v-html="phoneNumberLink(transport.disposer.phone_number)"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="transport.origin" class="col-12 card p-2 mt-2">
            <div class="row">
                <div class="col-12 d-flex justify-content-between align-items-center">
                    <strong>{{ t('Adres van verzending') }}</strong>
                    <a class="navigate" @click="openMap(transport.origin)" href="javascript:void(0)">
                        <i class="fa fa-location-arrow"></i>
                    </a>
                </div>
                <div class="col-12">
                    <div>
                        {{ translate(transport.origin.street) }} {{ transport.origin.house_number }}{{ this.stringIfNull(transport.origin.house_number_addition) }}<br/>
                        {{ translate(transport.origin.postal_code) }} {{ translate(transport.origin.city) }}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 card p-2 mt-2">
            <div class="row">
                <div class="col-12">
                    <strong>{{ t('IHM') }}</strong>
                </div>
                <div class="col-12">
                    <div>
                        {{ translate(transport.ihm.name) }}<br/>
                        {{ translate(transport.ihm.address.street) }} {{ transport.ihm.address.house_number }}{{ this.stringIfNull(transport.ihm.address.house_number_addition) }}<br/>
                        {{ translate(transport.ihm.address.postal_code) }} {{ translate(transport.ihm.address.city) }}<br/>
                        <div v-html="phoneNumberLink(transport.ihm.phone_number)"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 card p-2 mt-2">
            <div class="row">
                <div class="col-12">
                    <strong>{{ t('Vervoerder') }}</strong>
                </div>
                <div class="col-12">
                    <div>
                        {{ translate(transport.transporter.name) }}<br/>
                        {{ translate(transport.transporter.address.street) }} {{ transport.transporter.address.house_number }}{{ this.stringIfNull(transport.transporter.address.house_number_addition) }}<br/>
                        {{ translate(transport.transporter.address.postal_code) }} {{ translate(transport.transporter.address.city) }}<br/>
                        <div v-html="phoneNumberLink(transport.transporter.phone_number)"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 card p-2 mt-2">
            <div class="row">
                <div class="col-12 d-flex justify-content-between align-items-center">
                    <strong>{{ t('Verwerker') }}</strong>
                    <a class="navigate" @click="openMap(transport.receiver.address)" href="javascript:void(0)">
                        <i class="fa fa-location-arrow"></i>
                    </a>
                </div>
                <div class="col-12">
                    <div>
                        {{ translate(transport.receiver.name) }}<br/>
                        {{ translate(transport.receiver.address.street) }} {{ transport.receiver.address.house_number }}{{ this.stringIfNull(transport.receiver.address.house_number_addition) }}<br/>
                        {{ translate(transport.receiver.address.postal_code) }} {{ translate(transport.receiver.address.city) }}<br/>
                        <div v-html="phoneNumberLink(transport.receiver.phone_number)"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 card p-2 mt-2">
            <div class="row">
                <div class="col-12">
                    <strong>{{ t('Opmerkingen aan chauffeur') }}</strong>
                </div>
                <div class="col-12">
                    {{transport.note_to_driver}}
                </div>
            </div>
        </div>
    </div>
    <div v-if="show_cargo_for_transport">
        <div class="col-12 card p-2">
            <div class="row">
                <div class="col-9">
                    {{ (transport.form_number ? transport.form_number : '') }}
                </div>
                <div class="col-3 align-self-center text-end">
                    <a class="send-email" href="javascript:void(0)" @click="show_mail_dialog = !show_mail_dialog">
                        <i class="fa fa-envelope"></i>
                    </a>
                </div>
                <div v-if="show_mail_dialog" class="col-12">
                    <div class="form-inline">
                        <div class="form-group d-inline-block mr-2">
                            <input v-model="email_address" class="form-control form-control-sm" placeholder="Email"
                                   type="email">
                        </div>
                        <div class="form-group d-inline-block ms-1">
                            <button :disabled="!emailIsValid"
                                    class="btn btn-primary btn-sm"
                                    type="button"
                                    @click="sendEmail(email_address, transport.id)">
                                {{ t('Verstuur') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-for="(item, index) in transport.lines" :key="index" class="col-12 card p-2 mt-2">
            <div class="row">
                <div class="col-12 d-flex justify-content-between align-items-center card-title">
                    <strong>{{ item.eural_code }}{{ item.eural_code.is_dangerous ? '*' : '' }}</strong>
                </div>
                <div class="col-12">
                    <div>
                        <span class="fst-italic fw-bolder">{{ t('Omschrijving')}}:</span>
                    </div>
                    <div>
                        {{ item.waste_description }}
                    </div>
                </div>
                <div class="col-12 mt-4">
                    <div class="row">
                        <div class="col-6">{{t('Gewicht')}}:</div>
                        <div class="col-6">{{ this.formatWeight(item.planned_weight) }}</div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">{{t('Verwerking')}}:</div>
                        <div class="col-6">{{ this.translate(item.processing_method) }}</div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">{{t('Fys. eigenschappen')}}:</div>
                        <div class="col-6">{{ this.translate(item.physical_properties) ?? '-' }}</div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">{{t('Chem. samenstelling')}}:</div>
                        <div class="col-6">{{ this.translate(item.chemical_composition) ?? '-' }}</div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">{{t('Verpakkingstype')}}:</div>
                        <div class="col-6">{{ item.packaging_type ? item.packaging_type : '-' }} {{ (item.amount_of_packages > 0 && `${item.amount_of_packages}` !== null ? `(${item.amount_of_packages}x)` : '') }}</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import Agent from "@/agent";

export default {
    name: 'TransportView',

    props: {
        transport: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        show_mail_dialog: false,
        email_address: '',
    }),

    methods:{
        sendEmail(email_address, transport_id) {
            Agent.Transport.sendEmail(email_address, [transport_id], this.$store.state.language).then(response => {
                this.$swal({
                    title: this.t('Email verzonden'),
                    text: this.t('De email is verzonden naar ') + email_address,
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
            }).catch(error => {
                console.log(error);
                this.$swal({
                    title: 'Error',
                    text: error.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            });
            this.show_mail_dialog = false
            this.email_address = ''
        },
    },

    computed: {
        emailIsValid() {
            return this.validateEmail(this.email_address);
        },
        show_cargo_for_transport() {
            return this.$store.state.show_cargo_for_transport || this.$store.state.show_selected_loaded_transport_lines;
        }
    },
    watch: {
        'transport': {
            handler() {
                this.$store.dispatch('setShowCargoForTransport', false);
                this.$store.dispatch('setShowSelectedLoadedTransportLines', false);
            },
            deep: true
        },
    },
}

</script>

<style scoped>
.send-email {
    color: #000;
}
</style>