<template>
    <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">
    <RouterView/>

</template>

<script>
import {RouterView} from 'vue-router';
import pkg from "../package.json";

export default {
    name: 'App',

    components: {
        RouterView
    },

    data: () => ({
        version: pkg.version
    }),

    watch: {
        '$store.state.mailbox': function(newValue) {
            if (!newValue) {
                this.$router.push({ name: 'login' });
            }
        }
    },

    mounted() {
        document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=no');
        document.title = pkg.title;
    }
}

</script>

<style>
    html {
        background:#aaa;
        height: calc(100vh - 55px);
    }
    ::-webkit-input-placeholder {
        font-style: italic;
    }
    :-moz-placeholder {
        font-style: italic;
    }
    ::-moz-placeholder {
        font-style: italic;
    }
    :-ms-input-placeholder {
        font-style: italic;
    }
    .page-header {
        background-color: #fff;
        padding: 10px;
        text-align: center;
        font-weight: bold;
    }

    html, body{
        height: 100vh;
    }

    #login-bg {
        background-image: url('./assets/bottom-login.png');
        background-position: bottom;
        background-repeat: no-repeat;
        height: calc(100vh - 60px);
    }

    #login-select-method {
        background-color: #f9f9f9;
        width: 50%;
        margin: 0 auto;
        padding: 20px;
        border-radius: 10px;
        border: 1px solid #e5e5e5;
    }

    #logo {
        width: 200px;
        margin-top: 100px;
    }

    #login-submit-email {
        background-color: #2c3e50;
        border-color: #2c3e50;
        margin-top:10px;
    }

    #login-submit-code {
        margin-top:10px;
    }

    #login-submit-code-back {
        margin-top:10px;
    }

    #code {
        text-align: center;
        font-size: 20px;
        letter-spacing: 10px;
    }

    h4 {
        text-align: center;
    }

    #login-select-method .form-group {
        position: relative;
    }

    #email {
        padding-right:25px;
    }

    #clear_email {
        position: absolute;
        right: 15px;
        top: 8px;
    }

    footer {
        position: absolute;
        bottom: 0;
        width: 100%;
    }


    @media (max-width: 768px) {
        #login-select-method {
            width: 75%;
        }
    }


    @media (max-width: 420px) {
        #login-select-method {
            width: 100%;
        }
    }
</style>