<template>

<div>
    <ul id="update-cargo-list">
        <li class="card mb-2 p-2" :key="index" v-for="(item, index) in cloned_lines">
            <div class="row mb-3" >
                <div class="col-9">
                    <textarea class="form-control form-control-sm" rows="2" v-model="item.waste_description"></textarea>
                </div>
                <div class="col-3 ps-0">
                    <div class="input-group input-group-sm">
                        <input type="number" class="form-control form-control-sm" v-model="item.received_weight">
                        <span class="input-group-text">kg</span>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-9">
                    <input type="text" :placeholder="t('verpakkingstype')" class="form-control form-control-sm" v-model="item.packaging_type">
                </div>
                <div class="col-3 ps-0">
                    <div class="input-group input-group-sm">
                        <input type="number" class="form-control form-control-sm" v-model="item.amount_of_packages">
                        <span class="input-group-text">st</span>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</div>

</template>

<script>

export default {
    name: 'CargoPage',
    data: () => ({
        cloned_lines: [],
    }),
    methods: {

    },
    computed: {

    },
    watch: {
        '$store.state.selected_transport': {
            handler: function (val, oldVal) {
                this.cloned_lines = JSON.parse(JSON.stringify(val.lines));
            },
            deep: true,
        },
        'cloned_lines': {
            handler: function (newLines, oldLines) {
                let valid = true;
                this.cloned_lines.forEach((item) => {
                    item.received_weight = parseInt(item.received_weight)
                    if(item.received_weight < 0) item.received_weight = 0;
                    item.amount_of_packages = parseInt(item.amount_of_packages)
                    if(item.amount_of_packages < 0) item.amount_of_packages = 0;

                    if(isNaN(item.received_weight)
                        || item.received_weight < 0
                        || item.received_weight == null
                        || item.packaging_type === ''
                        || item.packaging_type == null
                        || isNaN(item.amount_of_packages)
                        || item.amount_of_packages === ''
                        || item.amount_of_packages < 0
                        || item.amount_of_packages == null
                        || item.waste_description === ''
                        || item.waste_description == null
                    ) {
                        valid = false
                    }
                });
                if(valid){
                    this.$store.dispatch('setTransportLines', newLines);
                    this.$store.dispatch('setStep3Valid', true);
                }else{
                    this.$store.dispatch('setStep3Valid', false);
                    this.$store.dispatch('setTransportLines', []);
                }
            },
            deep: true,
        },
    },
    mounted() {
        if(this.$store.state.transport_fields_update_values.transport_lines && this.$store.state.transport_fields_update_values.transport_lines.length > 0) {
            this.cloned_lines = this.$store.state.transport_fields_update_values.transport_lines;
        }else {
            this.cloned_lines = JSON.parse(JSON.stringify(this.$store.state.selected_transport.lines));
        }

        this.cloned_lines.forEach((line) => {
            line.received_weight = line.received_weight ? parseInt(line.received_weight) : parseInt(line.planned_weight);
        });
    },
};

</script>

<style>

#update-cargo-list {
    list-style-type: none;
    padding: 0;
}

#update-cargo-list li input, #update-cargo-list li textarea, #update-cargo-list li .input-group-text {
    font-size: 12px !important;
}

</style>