<template>
    <div class="card p-2 mb-2">
        <div v-if="!is_signed_by_producer">
            <div class="form-group">
                <label for="transporter_name">{{ t('Handtekening producent') }}</label>
                <div class="signature-wrapper">
                    <signature-pad ref="signaturePad"
                                   @signature-updated-producer="handleSignatureUpdate"
                                   :signature="signature"
                                   :role="'producer'"></signature-pad>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <div class="form-group">
                        <select v-model="producer_selected_contact" class="form-select form-select-sm">
                            <option selected value="-1">{{t('Kies een bestaande contactpersoon')}}</option>
                            <option v-for="contact in available_contacts" :key="contact.id" :value="contact.id">
                                {{ formatName(contact.firstname, contact.lastname, contact.lastname_prefix) }}
                            </option>
                        </select>
                    </div>
                    <div class="row mt-4">
                        <div class="col-4 pe-0">
                            <div class="form-group">
                                <input type="text" class="form-control form-control-sm"
                                       :disabled="contact_selected"
                                       :placeholder="t('Voornaam')" v-model="producer_first_name">
                            </div>
                        </div>
                        <div class="col-3 pe-0">
                            <div class="form-group">
                                <input type="text" class="form-control form-control-sm"
                                       :disabled="contact_selected"
                                       :placeholder="t('Tussenvoegsel')" v-model="producer_insertion">
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="form-group">
                                <input type="text" class="form-control form-control-sm"
                                       :disabled="contact_selected"
                                       :placeholder="t('Achternaam')" v-model="producer_last_name">
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-5 pe-0">
                            <div class="form-group">
                                <input type="text" class="form-control form-control-sm"
                                       :disabled="contact_selected"
                                       :placeholder="t('E-mailadres')" v-model="producer_email_address">
                            </div>
                        </div>
                        <div class="col-4 pe-0">
                            <div class="form-group">
                                <input type="phone" class="form-control form-control-sm"
                                       :disabled="contact_selected"
                                       :placeholder="t('+315421548754')" v-model="producer_phone_number">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <select :disabled="contact_selected"
                                        class="form-select form-select-sm" v-model="producer_language">
                                    <option value="nl">NL</option>
                                    <option value="en">EN</option>
                                    <option value="fr">FR</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div v-if="!contact_selected" class="row mt-2">
                        <div class="col-6">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" v-model="producer_save_contact" id="save-contact">
                                <label class="form-check-label col-form-label-sm" for="save-contact">
                                    {{ t('Contactpersoon opslaan') }}
                                </label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" v-model="producer_not_available" id="not-available">
                                <label class="form-check-label col-form-label-sm" for="not-available">
                                    {{ t('Niemand aanwezig') }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <h5 class="text-center">{{ t('Producent heeft reeds getekend') }}</h5>
        </div>
    </div>
</template>

<script>
import SignaturePad from "@/components/SignaturePad.vue";
import Agent from "@/agent";

export default {
    name: 'ProducerSignPage',
    components: {
        SignaturePad
    },
    data: () => ({
        producer_selected_contact: -1,
        available_contacts: [],
        producer_first_name: '',
        producer_last_name: '',
        producer_insertion: '',
        producer_email_address: '',
        producer_phone_number: '',
        producer_language: '',
        producer_save_contact: false,
        producer_not_available: false,
        signature: null,

    }),
    methods: {
        handleSignatureUpdate(base_64) {
            console.log(base_64)
            this.$store.dispatch('setProducerSignature', base_64);
        },
        loadContacts(callback) {
            Agent.Transport.getContacts(this.transport.id, this.transport.disposer.id).then(response => {
                this.available_contacts = response.data;
                if(callback) callback();
            }).catch(error => {
                console.log(error);
            });
        },
    },
    watch: {
        'producer_selected_contact': function (val) {
            val = parseInt(val);

            if (val !== -1) {
                this.$store.dispatch('setProducerSelectedContact', val);
                const contact = this.available_contacts.find(contact => contact.id === val);
                this.producer_first_name = contact.firstname;
                this.producer_last_name = contact.lastname;
                this.producer_insertion = contact.lastname_prefix;
                this.producer_email_address = contact.email;
                this.producer_phone_number = contact.phone_number;
                this.producer_language = contact.communication_language;
            } else {
                this.$store.dispatch('setProducerSelectedContact', null);
                this.producer_first_name = '';
                this.producer_last_name = '';
                this.producer_insertion = '';
                this.producer_email_address = '';
                this.producer_phone_number = '';
                this.producer_language = this.$store.state.language;
            }
        },
        'producer_first_name': function (val) {
            this.$store.dispatch('setProducerFirstName', val);
        },
        'producer_last_name': function (val) {
            this.$store.dispatch('setProducerLastName', val);
        },
        'producer_insertion': function (val) {
            this.$store.dispatch('setProducerInsertion', val);
        },
        'producer_email_address': function (val) {
            this.$store.dispatch('setProducerEmailAddress', val);
        },
        'producer_phone_number': function (val) {
            this.$store.dispatch('setProducerPhoneNumber', val);
        },
        'producer_language': function (val) {
            this.$store.dispatch('setProducerLanguage', val);
        },
        'producer_save_contact': function (val) {
            this.$store.dispatch('setProducerSaveContact', val);
        },
        'producer_not_available': function (val) {
            this.$store.dispatch('setProducerNotAvailable', val);
        },
    },

    mounted() {
        this.producer_language = this.$store.state.language;
        if(this.$store.state.transport_fields_update_values && this.$store.state.transport_fields_update_values.producer_signature) {
            this.signature = this.$store.state.transport_fields_update_values.producer_signature;
        }
        if(this.$store.state.transport_fields_update_values && this.$store.state.transport_fields_update_values.producer_save_contact_details) {
            this.producer_save_contact = this.$store.state.transport_fields_update_values.producer_save_contact_details;
        }
        if(this.$store.state.transport_fields_update_values && this.$store.state.transport_fields_update_values.producer_not_available) {
            this.producer_not_available = this.$store.state.transport_fields_update_values.producer_not_available;
        }
        if (this.$store.state.transport_fields_update_values && this.$store.state.transport_fields_update_values.producer_first_name) {
            this.producer_first_name = this.$store.state.transport_fields_update_values.producer_first_name;
        }
        if (this.$store.state.transport_fields_update_values && this.$store.state.transport_fields_update_values.producer_last_name) {
            this.producer_last_name = this.$store.state.transport_fields_update_values.producer_last_name;
        }
        if (this.$store.state.transport_fields_update_values && this.$store.state.transport_fields_update_values.producer_insertion) {
            this.producer_insertion = this.$store.state.transport_fields_update_values.producer_insertion;
        }
        if (this.$store.state.transport_fields_update_values && this.$store.state.transport_fields_update_values.producer_email_address) {
            this.producer_email_address = this.$store.state.transport_fields_update_values.producer_email_address;
        }
        if (this.$store.state.transport_fields_update_values && this.$store.state.transport_fields_update_values.producer_phone_number) {
            this.producer_phone_number = this.$store.state.transport_fields_update_values.producer_phone_number;
        }
        if (this.$store.state.transport_fields_update_values && this.$store.state.transport_fields_update_values.producer_language) {
            this.producer_language = this.$store.state.transport_fields_update_values.producer_language;
        }
        this.loadContacts(() => {
            if (this.$store.state.transport_fields_update_values && this.$store.state.transport_fields_update_values.producer_selected_contact) {
                this.producer_selected_contact = this.$store.state.transport_fields_update_values.producer_selected_contact;
            }
        })
    },
    computed: {
        transport() {
            return this.$store.state.selected_transport ? this.$store.state.selected_transport : null;
        },
        is_signed_by_producer() {
            return this.$store.state.selected_transport.is_signed_by_producer
        },
        contact_selected() {
            return parseInt(this.producer_selected_contact) !== -1;
        },
    }
}

</script>

<style scoped>

</style>