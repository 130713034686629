import { createStore } from 'vuex';


function initialState() {
    return {
        user: null,
        mailbox: null,
        open_transports: [],
        loaded_transports: [],
        language: localStorage.getItem('language') || 'en',
        status: 'open',
        selected_transport: null,
        transport_fields_update_values: {},
        step: null,
        step1valid: true,
        step2valid: false,
        step3valid: false,
        step4valid: true,
        step5valid: true,
        unloadstep1valid: true,
        unloadstep2valid: true,
        unloadstep3valid: true,
        unloadstep4valid: true,
        page_header: '',
        show_cargo_for_transport: false,
        transporter_data:null,
        save_transporter_data_for_next_transports: true,
        do_start_transport: false,
        selected_receiver: null,
        selected_transports: [],
        transports_to_be_refused: [],
        refuse_selected_transports: false,
        show_refused_page: false,
        refused_action: null,
        refused_remark: null,
        transports_to_unload: [],
        show_unload_page: false,
        unload_step: null,
        do_end_transport: false,
        unloading_transports_manager: {},
        current_form: null,
        selected_loaded_transport: null,
        has_internet_connection: true,
        show_selected_loaded_transport_lines: false,
        is_installed: false,
        search_term: '',
        is_loading: false
    };
}

export default createStore({
    state: initialState(),
    mutations: {
        SET_USER(state, user) {
            state.user = user;
        },
        SET_MAILBOX(state, mailbox) {
            state.mailbox = mailbox;
        },
        SET_LANGUAGE(state, language) {
            state.language = language;
        },
        RESET_STATE(state) {
            Object.assign(state, initialState());
        },
        RESET_TRANSPORT_DATA(state){
            state.selected_transport = null;
            state.transport_fields_update_values = {};
            state.transporter_data = null;
            state.save_transporter_data_for_next_transports = true;
            state.show_cargo_for_transport = false;
            state.page_header = '';
            state.step = null;
            state.step1valid = true;
            state.step2valid = true;
            state.step3valid = false;
            state.step4valid = true;
            state.step5valid = true;
        },
        SET_OPEN_TRANSPORTS(state, transports) {
            state.open_transports = transports;
        },
        SET_LOADED_TRANSPORTS(state, transports) {
            state.loaded_transports = transports;
        },
        SET_STATUS(state, status) {
            state.status = status;
        },
        SET_SELECTED_TRANSPORT(state, transport) {
            state.selected_transport = transport;
        },
        SET_STEP(state, step) {
            state.step = step;
        },
        SET_STEP1_VALID(state, valid) {
            state.step1valid = valid;
        },
        SET_STEP2_VALID(state, valid) {
            state.step2valid = valid;
        },
        SET_STEP3_VALID(state, valid) {
            state.step3valid = valid;
        },
        SET_STEP4_VALID(state, valid) {
            state.step4valid = valid;
        },
        SET_STEP5_VALID(state, valid) {
            state.step5valid = valid;
        },
        SET_STEP6_VALID(state, valid) {
            state.step6valid = valid;
        },
        SET_PAGE_HEADER(state, header) {
            state.page_header = header;
        },
        SET_TRANSPORTER_SIGNATURE(state, signature) {
            state.transport_fields_update_values.transporter_signature = signature;
        },
        SET_TRANSPORT_LINES(state, lines) {
            state.transport_fields_update_values.transport_lines = lines;
        },
        SET_TRANSPORTER_FIRST_NAME(state, first_name) {
            state.transport_fields_update_values.transporter_first_name = first_name;
        },
        SET_TRANSPORTER_LAST_NAME(state, last_name) {
            state.transport_fields_update_values.transporter_last_name = last_name;
        },
        SET_TRANSPORTER_INSERTION(state, insertion) {
            state.transport_fields_update_values.transporter_insertion = insertion;
        },
        SET_PRODUCER_SIGNATURE(state, signature) {
            state.transport_fields_update_values.producer_signature = signature;
        },
        SET_SHOW_CARGO_FOR_TRANSPORT(state, show) {
            state.show_cargo_for_transport = show;
        },
        SET_START_GPS(state, start) {
            state.transport_fields_update_values.start_gps = start;
        },
        SET_END_GPS(state, end) {
            state.transport_fields_update_values.end_gps = end;
        },
        SET_TRANSPORT_REMARK(state, remark) {
            state.transport_fields_update_values.transport_remark = remark;
        },
        SET_SAVE_TRANSPORTER_DATA_FOR_NEXT_TRANSPORTS(state, data) {
            state.save_transporter_data_for_next_transports = data;
        },
        SET_TRANSPORTER_DATA(state, data) {
            state.transporter_data = data;
        },
        SET_PRODUCER_LANGUAGE(state, language) {
            state.transport_fields_update_values.producer_language = language;
        },
        SET_PRODUCER_FIRST_NAME(state, first_name) {
            state.transport_fields_update_values.producer_first_name = first_name;
        },
        SET_PRODUCER_LAST_NAME(state, last_name) {
            state.transport_fields_update_values.producer_last_name = last_name;
        },
        SET_PRODUCER_INSERTION(state, insertion) {
            state.transport_fields_update_values.producer_insertion = insertion;
        },
        SET_PRODUCER_EMAIL_ADDRESS(state, email) {
            state.transport_fields_update_values.producer_email_address = email;
        },
        SET_PRODUCER_PHONE_NUMBER(state, phone_number) {
            state.transport_fields_update_values.producer_phone_number = phone_number;
        },
        SET_PRODUCER_NOT_AVAILABLE(state, not_available) {
            state.transport_fields_update_values.producer_not_available = not_available;
        },
        SET_PRODUCER_SAVE_CONTACT(state, save) {
            state.transport_fields_update_values.producer_save_contact = save;
        },
        SET_PRODUCER_SELECTED_CONTACT(state, contact) {
            state.transport_fields_update_values.producer_selected_contact = contact;
        },
        DO_START_TRANSPORT(state, start) {
            state.do_start_transport = start;
        },
        SET_SELECTED_RECEIVER(state, receiver) {
            state.selected_receiver = receiver;
        },
        SET_SELECTED_TRANSPORTS(state, transports) {
            state.selected_transports = transports;
        },
        SET_REFUSE_SELECTED_TRANSPORTS(state, selected) {
            state.refuse_selected_transports = selected;
        },
        SET_TRANSPORTS_TO_BE_REFUSED(state, transports) {
            state.transports_to_be_refused = transports;
        },
        SET_SHOW_REFUSED_PAGE(state, show) {
            state.show_refused_page = show;
        },
        SET_REFUSED_ACTION(state, action) {
            state.refused_action = action;
        },
        SET_REFUSED_REMARK(state, remark) {
            state.refused_remark = remark;
        },
        SET_TRANSPORTS_TO_UNLOAD(state, transports) {
            state.transports_to_unload = transports;
        },
        SET_SHOW_UNLOAD_PAGE(state, show) {
            state.show_unload_page = show;
        },
        SET_UNLOAD_STEP1_VALID(state, valid) {
            state.unloadstep1valid = valid;
        },
        SET_UNLOAD_STEP2_VALID(state, valid) {
            state.unloadstep2valid = valid;
        },
        SET_UNLOAD_STEP3_VALID(state, valid) {
            state.unloadstep3valid = valid;
        },
        SET_UNLOAD_STEP4_VALID(state, valid) {
            state.unloadstep4valid = valid;
        },
        SET_UNLOAD_STEP(state, step) {
            state.unload_step = step;
        },
        SET_DO_END_TRANSPORT(state, transport) {
            state.do_end_transport = transport;
        },
        SET_UNLOADING_TRANSPORTS_MANAGER(state, manager) {
            state.unloading_transports_manager = manager;
        },
        SET_CURRENT_FORM(state, form) {
            state.current_form = form;
        },
        SET_RECEIVER_SELECTED_CONTACT(state, contact) {
            state.transport_fields_update_values.receiver_selected_contact = contact;
        },
        SET_RECEIVER_FIRST_NAME(state, first_name) {
            state.transport_fields_update_values.receiver_first_name = first_name;
        },
        SET_RECEIVER_LAST_NAME(state, last_name) {
            state.transport_fields_update_values.receiver_last_name = last_name;
        },
        SET_RECEIVER_INSERTION(state, insertion) {
            state.transport_fields_update_values.receiver_insertion = insertion;
        },
        SET_RECEIVER_EMAIL_ADDRESS(state, email) {
            state.transport_fields_update_values.receiver_email_address = email;
        },
        SET_RECEIVER_PHONE_NUMBER(state, phone_number) {
            state.transport_fields_update_values.receiver_phone_number = phone_number;
        },
        SET_RECEIVER_SAVE_CONTACT(state, save) {
            state.transport_fields_update_values.receiver_save_contact = save;
        },
        SET_RECEIVER_NOT_AVAILABLE(state, not_available) {
            state.transport_fields_update_values.receiver_not_available = not_available;
        },
        SET_RECEIVER_SIGNATURE(state, signature) {
            state.transport_fields_update_values.receiver_signature = signature;
        },
        SET_RECEIVER_LANGUAGE(state, language) {
            state.transport_fields_update_values.receiver_language = language;
        },
        SET_SELECTED_LOADED_TRANSPORT(state, transport) {
            state.selected_loaded_transport = transport;
        },
        SET_SHOW_SELECTED_LOADED_TRANSPORT_LINES(state, show) {
            state.show_selected_loaded_transport_lines = show;
        },
        SET_HAS_INTERNET_CONNECTION(state, has_connection) {
            state.has_internet_connection = has_connection;
        },
        SET_IS_INSTALLED(state, is_installed) {
            state.is_installed = is_installed;
        },
        SET_SEARCH_TERM(state, term) {
            state.search_term = term;
        },
        SET_IS_LOADING(state, is_loading) {
            state.is_loading = is_loading;
        }
    },
    actions: {
        login({ commit }, user) {
            commit('SET_USER', user);
        },
        logout({ commit }) {
            commit('SET_USER', null);
            commit('SET_MAILBOX', null);
            localStorage.removeItem('mailbox');
            localStorage.removeItem('user');
        },
        setMailbox({ commit }, mailbox) {
            commit('SET_MAILBOX', mailbox);
        },
        unsetMailbox({ commit }) {
            commit('SET_MAILBOX', null);
        },
        resetStore({ commit }) {
            commit('RESET_STATE');
        },
        resetTransportData({ commit }) {
            commit('RESET_TRANSPORT_DATA');
        },
        setUser({ commit }, user) {
            commit('SET_USER', user);
        },
        setLanguage({ commit }, language) {
            commit('SET_LANGUAGE', language);
            localStorage.setItem('language', language);
        },
        setOpenTransports({ commit }, transports) {
            commit('SET_OPEN_TRANSPORTS', transports);
        },
        setLoadedTransports({ commit }, transports) {
            commit('SET_LOADED_TRANSPORTS', transports);
        },
        setStatus({ commit }, status) {
            commit('SET_STATUS', status);
        },
        setSelectedTransport({ commit }, transport) {
            commit('SET_SELECTED_TRANSPORT', transport);
        },
        setStep({ commit }, step) {
            commit('SET_STEP', step);
        },
        setStep1Valid({ commit }, valid) {
            commit('SET_STEP1_VALID', valid);
        },
        setStep2Valid({ commit }, valid) {
            commit('SET_STEP2_VALID', valid);
        },
        setStep3Valid({ commit }, valid) {
            commit('SET_STEP3_VALID', valid);
        },
        setStep4Valid({ commit }, valid) {
            commit('SET_STEP4_VALID', valid);
        },
        setStep5Valid({ commit }, valid) {
            commit('SET_STEP5_VALID', valid);
        },
        setStep6Valid({ commit }, valid) {
            commit('SET_STEP6_VALID', valid);
        },
        setPageHeader({ commit }, header) {
            commit('SET_PAGE_HEADER', header);
        },
        setTransporterSignature({ commit }, signature) {
            commit('SET_TRANSPORTER_SIGNATURE', signature);
        },
        setTransportLines({ commit }, lines) {
            commit('SET_TRANSPORT_LINES', lines);
        },
        setTransporterFirstName({ commit }, first_name) {
            commit('SET_TRANSPORTER_FIRST_NAME', first_name);
        },
        setTransporterLastName({ commit }, last_name) {
            commit('SET_TRANSPORTER_LAST_NAME', last_name);
        },
        setTransporterInsertion({ commit }, insertion) {
            commit('SET_TRANSPORTER_INSERTION', insertion);
        },
        setSaveTransporterDataForNextTransports({ commit }, data) {
            if(data !== {}){
                localStorage.setItem('transporter_data', JSON.stringify(data));
            }else {
                localStorage.removeItem('transporter_data');
            }
            commit('SET_SAVE_TRANSPORTER_DATA_FOR_NEXT_TRANSPORTS', !!data);
            commit('SET_TRANSPORTER_DATA', data);
        },
        setProducerSignature({ commit }, signature) {
            commit('SET_PRODUCER_SIGNATURE', signature);
        },
        setProducerLanguage({ commit }, language) {
            commit('SET_PRODUCER_LANGUAGE', language);
        },
        setProducerFirstName({ commit }, first_name) {
            commit('SET_PRODUCER_FIRST_NAME', first_name);
        },
        setProducerLastName({ commit }, last_name) {
            commit('SET_PRODUCER_LAST_NAME', last_name);
        },
        setProducerInsertion({ commit }, insertion) {
            commit('SET_PRODUCER_INSERTION', insertion);
        },
        setProducerEmailAddress({ commit }, email) {
            commit('SET_PRODUCER_EMAIL_ADDRESS', email);
        },
        setProducerPhoneNumber({ commit }, phone_number) {
            commit('SET_PRODUCER_PHONE_NUMBER', phone_number);
        },
        setShowCargoForTransport({ commit }, show) {
            commit('SET_SHOW_CARGO_FOR_TRANSPORT', show);
        },
        setStartGPS({ commit }, start) {
            commit('SET_START_GPS', start);
        },
        setEndGPS({ commit }, end) {
            commit('SET_END_GPS', end);
        },
        setTransportRemark({ commit }, remarks) {
            commit('SET_TRANSPORT_REMARK', remarks);
        },
        setProducerNotAvailable({ commit }, not_available) {
            commit('SET_PRODUCER_NOT_AVAILABLE', not_available);
        },
        setProducerSaveContact({ commit }, save) {
            commit('SET_PRODUCER_SAVE_CONTACT', save);
        },
        setProducerSelectedContact({ commit }, contact) {
            commit('SET_PRODUCER_SELECTED_CONTACT', contact);
        },
        setDoStartTransport({ commit }, start) {
            commit('DO_START_TRANSPORT', start);
        },
        setSelectedReceiver({ commit }, receiver) {
            commit('SET_SELECTED_RECEIVER', receiver);
        },
        setSelectedTransports({ commit }, transports) {
            commit('SET_SELECTED_TRANSPORTS', transports);
        },
        setRefuseSelectedTransports({ commit }, selected) {
            commit('SET_REFUSE_SELECTED_TRANSPORTS', selected);
        },
        setTransportsToBeRefused({ commit }, transports) {
            commit('SET_TRANSPORTS_TO_BE_REFUSED', transports);
        },
        setShowRefusedPage({ commit }, show) {
            commit('SET_SHOW_REFUSED_PAGE', show);
        },
        setRefusedAction({ commit }, action) {
            commit('SET_REFUSED_ACTION', action);
        },
        setRefusedRemark({ commit }, remark) {
            commit('SET_REFUSED_REMARK', remark);
        },
        setTransportsToUnload({ commit }, transports) {
            commit('SET_TRANSPORTS_TO_UNLOAD', transports);
        },
        setShowUnloadPage({ commit }, show) {
            commit('SET_SHOW_UNLOAD_PAGE', show);
        },
        setUnloadStep1Valid({ commit }, valid) {
            commit('SET_UNLOAD_STEP1_VALID', valid);
        },
        setUnloadStep2Valid({ commit }, valid) {
            commit('SET_UNLOAD_STEP2_VALID', valid);
        },
        setUnloadStep3Valid({ commit }, valid) {
            commit('SET_UNLOAD_STEP3_VALID', valid);
        },
        setUnloadStep4Valid({ commit }, valid) {
            commit('SET_UNLOAD_STEP4_VALID', valid);
        },
        setUnloadStep({ commit }, valid) {
            commit('SET_UNLOAD_STEP', valid);
        },
        setDoEndTransport({ commit }, transport) {
            commit('SET_DO_END_TRANSPORT', transport);
        },
        setUnloadingTransportsManager({ commit }, manager) {
            commit('SET_UNLOADING_TRANSPORTS_MANAGER', manager);
        },
        setCurrentForm({ commit }, form) {
            commit('SET_CURRENT_FORM', form);
        },
        setReceiverSelectedContact({ commit }, contact) {
            commit('SET_RECEIVER_SELECTED_CONTACT', contact);
        },
        setReceiverFirstName({ commit }, first_name) {
            commit('SET_RECEIVER_FIRST_NAME', first_name);
        },
        setReceiverLastName({ commit }, last_name) {
            commit('SET_RECEIVER_LAST_NAME', last_name);
        },
        setReceiverInsertion({ commit }, insertion) {
            commit('SET_RECEIVER_INSERTION', insertion);
        },
        setReceiverEmailAddress({ commit }, email) {
            commit('SET_RECEIVER_EMAIL_ADDRESS', email);
        },
        setReceiverPhoneNumber({ commit }, phone_number) {
            commit('SET_RECEIVER_PHONE_NUMBER', phone_number);
        },
        setReceiverSaveContact({ commit }, save) {
            commit('SET_RECEIVER_SAVE_CONTACT', save);
        },
        setReceiverNotAvailable({ commit }, not_available) {
            commit('SET_RECEIVER_NOT_AVAILABLE', not_available);
        },
        setReceiverSignature({ commit }, signature) {
            commit('SET_RECEIVER_SIGNATURE', signature);
        },
        setReceiverLanguage({ commit }, language) {
            commit('SET_RECEIVER_LANGUAGE', language);
        },
        setSelectedLoadedTransport({ commit }, transport) {
            commit('SET_SELECTED_LOADED_TRANSPORT', transport);
        },
        setShowSelectedLoadedTransportLines({ commit }, show) {
            commit('SET_SHOW_SELECTED_LOADED_TRANSPORT_LINES', show);
        },
        setHasInternetConnection({ commit }, has_connection) {
            commit('SET_HAS_INTERNET_CONNECTION', has_connection);
        },
        setIsInstalled({ commit }, is_installed) {
            commit('SET_IS_INSTALLED', is_installed);
        },
        setSearchTerm({ commit }, term) {
            commit('SET_SEARCH_TERM', term);
        },
        setIsLoading({ commit }, is_loading) {
            commit('SET_IS_LOADING', is_loading);
        }
    }
});


