<template>
    <div v-if="show" class="modal-component">
        <div class="modal-content">
            <div class="modal-header">
                header
            </div>
            <div class="modal-body">
                body boidy asd asd ad as dasd ad as das as
                 assa dasd asd asd asd as
                d asd ad asd asd
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary">OK</button>
            </div>
        </div>
    </div>
</template>

<script>
import Agent from "@/agent";

export default {
    name: 'ModalComponent',

    data: () => ({
        show: false,
    }),
}

</script>

<style scoped>
.modal-component {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 15px;
    z-index: 99999;
}

.modal-content {
    background-color: white;
    width: 90%;
    border-radius: 10px;
}

.modal-header{
    font-size: 18px;
    font-weight: bold;
    border-bottom: 1px solid #ccc;
    padding:5px 10px;
}

.modal-body{
    padding:10px;
    text-align: left;
}

.modal-footer{
    border-top: 1px solid #ccc;
    padding:5px 10px;
}

</style>