<template>
    <div class="row transport-item">
        <div class="col-12 card p-2">
            <div class="form-group">
                <label>{{t('Reden voor weigering transporten')}}</label>
                <textarea class="form-control" rows="3" v-model="refused_remark"></textarea>
            </div>
            <div class="form-group mt-3">
                <label>{{t('Vervolgactie')}}</label>
                <div class="input-group-sm">
                    <label>
                        <input type="radio" name="refused_action" value="back_to_producer" v-model="refused_action">
                        {{t('Terug naar producent')}}
                    </label>
                </div>
                <div class="input-group-sm">
                    <label>
                        <input type="radio" name="refused_action" value="to_different_receiver" v-model="refused_action">
                        {{t('Naar andere verwerker')}}
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Agent from "@/agent";

export default {
    name: 'RefusedPage',
    components: {},
    data: () => ({
        refused_action: null,
        refused_remark: '',
    }),
    watch: {
        '$store.state.refuse_selected_transports': function(val) {
            if(val) {
                if (this.$store.state.selected_transports.length > 0) {
                    this.refused_remark = '';
                    this.$swal({
                        title: this.t('Weet je zeker dat je deze transporten wilt weigeren?'),
                        html: this.t('Je kunt deze actie niet ongedaan maken.') + '<br><br>' +
                            '<table class="form-table">' +
                            `<tr><th>${this.t('Formuliernummer')}</th></tr>` +
                            this.refused_transports.map(transport => `<tr><td>${transport.form_number}</td></tr>`).join('') +
                            '</table>',

                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: this.t('Ja, weigeren'),
                        cancelButtonText: this.t('Annuleren'),
                    }).then((result) => {
                        if(result.isConfirmed){
                            Agent.Transport.refuseTransports({
                                transport_ids: this.$store.state.selected_transports,
                                refused_action: this.$store.state.refused_action,
                                refused_remark: this.$store.state.refused_remark,
                            }).then((result) => {
                                console.log(result.data);
                                console.log('x')
                                let errors = [];
                                let conflicts = [];
                                let successes = [];
                                if(Array.isArray(result.data)){
                                    result.data.forEach((response) => {
                                        console.log(response)
                                        if(response.status === 'error'){
                                            errors.push({
                                                form_number: this.$store.state.loaded_transports.find(transport => transport.id === response.id).form_number,
                                                message: response.message
                                            });
                                        }else if(response.status === 'conflict'){
                                            conflicts.push({
                                                form_number: this.$store.state.loaded_transports.find(transport => transport.id === response.id).form_number,
                                                message: response.message
                                            });
                                        }else if(response.status === 'success'){
                                            successes.push({
                                                form_number: this.$store.state.loaded_transports.find(transport => transport.id === response.id).form_number,
                                                message: response.message
                                            });
                                        }
                                    });
                                }
                                let swal_html = '';

                                if(successes.length > 0){
                                    swal_html += `<h4>${this.t('Successen')}</h4><table class="form-table">`;
                                    successes.forEach((success) => {
                                        swal_html += `<tr><th>${success.form_number}</th></tr><tr><td>${success.message}</td></tr>`;
                                    });
                                    swal_html += '</table>';
                                }
                                if(conflicts.length > 0){
                                    swal_html += `<h4>${this.t('Conflicten')}</h4><table class="form-table">`;
                                    conflicts.forEach((conflict) => {
                                        swal_html += `<tr><th>${conflict.form_number}</th></tr><tr><td>${conflict.message}</td></tr>`;
                                    });
                                    swal_html += '</table>';
                                }
                                if(errors.length > 0){
                                    swal_html += `<h4>${this.t('Fouten')}</h4><table class="form-table">`;
                                    errors.forEach((error) => {
                                        swal_html += `<tr><th>${error.form_number}</th></tr><tr><td>${error.message}</td></tr>`;
                                    });
                                    swal_html += '</table>';
                                }

                                let swal_title = this.t('Transporten geweigerd');
                                let swal_type = 'success';
                                if (successes.length > 0 && (errors.length > 0 || conflicts.length > 0)) {
                                    swal_title = this.t('Transporten geweigerd met fouten');
                                    swal_type = 'warning';
                                }else if(successes.length === 0 && (errors.length > 0 || conflicts.length > 0)){
                                    swal_title = this.t('Transporten niet geweigerd');
                                    swal_type = 'error';
                                }else if(successes.length === 0 && errors.length === 0 && conflicts.length === 0){
                                    swal_title = this.t('Transporten niet geweigerd. Er is iets mis gegaan...');
                                    swal_type = 'error';
                                }

                                this.$swal({
                                    title: swal_title,
                                    html: swal_html,
                                    icon: swal_type,
                                    confirmButtonText: this.t('Ok'),
                                }).then(() => {
                                    this.$store.dispatch('setRefuseSelectedTransports', false);
                                    this.$store.dispatch('setSelectedTransports', []);
                                    this.$store.dispatch('setSelectedReceiver', null);
                                    this.$store.dispatch('setShowRefusedPage', false);
                                    this.$store.dispatch('setRefusedAction', null);
                                    this.$store.dispatch('setRefusedRemark', null);
                                });
                            }).catch((error) => {
                                this.$swal({
                                    title: this.t('Er is iets misgegaan, probeer opnieuw.'),
                                    icon: 'error',
                                    text: error.response.data.message,
                                    confirmButtonText: this.t('Ok'),
                                }).then(() => {
                                    this.$store.dispatch('setRefuseSelectedTransports', false);
                                    this.$store.dispatch('setSelectedTransports', []);
                                    this.$store.dispatch('setSelectedReceiver', null);
                                    this.$store.dispatch('setShowRefusedPage', false);
                                    this.$store.dispatch('setRefusedAction', null);
                                    this.$store.dispatch('setRefusedRemark', null);
                                })
                            }).finally(() => {
                                this.updateAllTransportLists();
                            });
                        }else {
                            this.$store.dispatch('setRefuseSelectedTransports', false);
                        }
                    });
                }
            }
        },
        'refused_action': function(val) {
            this.$store.dispatch('setRefusedAction', val);
        },
        'refused_remark': function(val) {
            this.$store.dispatch('setRefusedRemark', val);
        },
    },
    computed: {
        refused_transports() {
            return this.$store.state.loaded_transports.filter(transport => {
                return this.$store.state.selected_transports.indexOf(transport.id) !== -1;
            });
        },
    },
    methods: {},
    mounted() {
        if(this.$store.state.refused_action){
            this.refused_action = this.$store.state.refused_action;
        }
        if(this.$store.state.refused_remark){
            this.refused_remark = this.$store.state.refused_remark;
        }
    },
}

</script>

<style>

.form-group > label {
    font-weight: bold;
}

.form-table{
    width: 100%;
    text-align: left;

}

.form-table td{
    padding: 5px;
}

.form-table th{
    padding: 5px;
    font-weight: bold;
}

.form-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

</style>