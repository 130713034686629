<template>
    <div id="layout">
        <map-box @new-gps="handleGPS" />
    </div>
</template>

<script>
import "/node_modules/mapbox-gl/dist/mapbox-gl.css";
import MapBox from "@/components/Map.vue";

export default {
    name: "MapPage",
    props: {
        callback: {
            type: String,
            default: 'setStartGPS'
        },
    },
    components: {
        MapBox,
    },
    methods: {
        handleGPS(gps) {
            this.$store.dispatch(this.callback, gps);
        },
    },
};
</script>

<style scoped>
#layout {
    position: relative;
    height: calc(100vh - 175px);
    overflow: hidden;
}
</style>
