<script>
import Agent from "@/agent";
import QRCode from 'qrcode';

export default {
    methods: {
        async updateAllTransportLists(statuses = ['open', 'loaded']){
            for (const status of statuses) {
                try {
                    const response = await Agent.Transport.getTransports(status);
                    this.$store.dispatch(`set${this.firstLetterUppercase(status)}Transports`, response.data);

                    if(status === 'loaded'){
                        const transportsWithQR = await Promise.all(response.data.map(async (transport) => {
                            const img_base64 = await QRCode.toDataURL(transport.document_url);
                            return {
                                id: transport.id,
                                form_number: transport.form_number,
                                document_url: transport.document_url,
                                img_base64: img_base64
                            };
                        }));
                        localStorage.setItem('loaded_transports', JSON.stringify(transportsWithQR));
                    }
                } catch (error) {
                    console.error(error);
                    this.$swal({
                        title: 'Error',
                        text: error.response.data.message,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    });
                }
            }
        }
    }
}

</script>